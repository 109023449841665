import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AsyncSubject, Observable } from 'rxjs';
import { PrincipalService } from './principal.service';
import { AuthTokenService } from './auth-token.service';
import {Router} from '@angular/router';
import { ApplicationDateService } from '@app/shared/date/application-date.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private http: HttpClient,
    private principalService: PrincipalService,
    private authTokenService: AuthTokenService,
    private applicationDateService:ApplicationDateService,
              private router: Router,
              public referenceCodeService: ReferenceCodeService) { }

  testAsyncSubject(input: any): Observable<any> {
    const ret = new AsyncSubject();
    ret.next('hi');
    setTimeout(function() {
      ret.next('hello');
      ret.complete();
    }, 2000);
    return ret;
  }

  login(credentials: any): Observable<any> {
    this.authTokenService.clearToken();
    const returnAsync = new AsyncSubject();
    // const data = {
    //   username: credentials.username,
    //   password: credentials.password,
    //   rememberMe: credentials.rememberMe
    // };

    let data = new HttpParams()
  .set('j_username', credentials.username)
    .set('j_password', credentials.password)
    .set('remember-me', credentials.rememberMe);
    // let headers = new HttpHeaders({
    //         'Content-Type': 'application/x-www-form-urlencoded',
    //         'Accept':'application/json'
    //     });

    const headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Accept', 'application/json');
    this.http.post('resources/j_spring_security_check', data, {headers, observe: 'response' }).pipe(take(1)).subscribe((response) => {
      if (response.body) {
        this.authTokenService.storeAuthenticationToken(response.body, credentials.rememberMe);
        this.applicationDateService.syncDate();
        this.principalService.identity(true).then((account) => {
          returnAsync.next(account);
          returnAsync.complete();
        }, (accountError) => {
          returnAsync.error(accountError);
          returnAsync.complete();
        });
      } else {
        returnAsync.error(response);
        returnAsync.complete();
      }
    }, (response) => {
      returnAsync.error(response);
      returnAsync.complete();
    });
    return returnAsync;
  }

  loginWithToken(jwt, rememberMe) {
    this.authTokenService.clearToken();
    const returnAsync = new AsyncSubject();
    if (jwt) {
      this.authTokenService.storeAuthenticationToken(jwt, rememberMe);
      this.principalService.identity(true).then((account) => {
        returnAsync.next(account);
        returnAsync.complete();
      }, (accountError) => {
        returnAsync.error(accountError);
        returnAsync.complete();
      });
      return Promise.resolve(jwt);
    } else {
      return Promise.reject('Authentication rejected'); // Put appropriate error message here
    }
  }

  logout() {
   /* this.http.post("logout", {}).subscribe((response) => {
      this.authTokenService.clearToken();
      // this.principalService.authenticate(null);
      this.router.navigate(['signin']);
    })*/
    // TODO needs to implement server side logout api
    this.authTokenService.clearToken();
    this.principalService.authenticate(null);
    this.router.navigateByUrl('signin');
    this.referenceCodeService.clearReferenceCodeFetchedData();
  }

  resetPassword(password: any, key: any): Observable<any> {
    const data = {
      key: key,
      newPassword: password.value,
    };
    return this.http.post('api/account/reset-password/finish', data, { observe: 'response' });
  }

  checkResetKey(key: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('key', key);
    return this.http.get<any>('api/account/checkResetKey', {
      params, observe: 'response'
    });
  }

  sendMail(email: any): Observable<any> {
    return this.http.post('api/account/reset-password/init', email, { observe: 'response' });
  }

  changePassword(formValue: any): Observable<any> {
    const data = {
      currentPassword: formValue.oldPassword,
      newPassword: formValue.newPassword,
    };
    return this.http.post('api/account/change-password', data, { observe: 'response' });
  }

}
