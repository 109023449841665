import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { ReportLink } from './reports.model';
import { map } from 'rxjs/operators';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
@Injectable({
    providedIn: 'root'
})
export class ReportService {

    private APIv1 = 'api/v1/reports';

    constructor(private http: HttpClient,
      private customDatepipe: DateFormatPipe,) { }

    getReportLinks(category: string): Observable<HttpResponse<ReportLink[]>> {
      return this.http.get<ReportLink[]>(this.APIv1 + '/' + category , { observe: 'response' });
    }

    getReportParameters(reportLink: ReportLink) {
      return this.http.post<ReportLink>(this.APIv1 + '/' + reportLink.uid
          + '/parameters', reportLink, {observe: 'response'});
    }

    getParameterOptions(reportLink: ReportLink, parameterType: string,
        parameterIndex: number) {
      return this.http.post<NameValueDto>(this.APIv1 + '/' + reportLink.uid
          + '/parameters/' + parameterType + '/' + parameterIndex
          + '/options', reportLink, {observe: 'response'});
    }

    generateReport(reportLink: ReportLink): Observable<any> {
      return this.http.post('api/v1/applications/' + reportLink.contextUid + '/reports/' + reportLink.uid + '/generate', reportLink,
        { observe: 'response', responseType: 'blob'}
      )
  }

}

