import { Component, OnInit, OnChanges, Input, SimpleChanges, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { ApplicantAddress, Address, AddressLink } from './applicant-address.model';
import { LoanApplication, Person ,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { MatSnackBar } from '@angular/material';
import { ValidationService } from '../../../application-validators/validation.service';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { ControlValidators } from '@app/applications/application-validators/control-validators.directive';
import { SETTING_DATAFORMAT_EMAIL_FORMAT } from '@app/constants/data.constants';

@Component({
  selector: 'eng-applicant-address',
  templateUrl: 'applicant-address.template.html',
  styleUrls: ['../../application-details.styles.scss']
})


export class ApplicantAddressComponent implements OnInit, OnChanges {

  @Input()
  application: LoanApplication;

  @Input()
  applicant: Person;

  @Input()
    menuCode:string;

  @ViewChild('sameAsDropdown')
    sameAsDropdown;

  applicantAddresses: ApplicantAddress[];

  applicantAddressForm: FormGroup;

  isFormDisabled: boolean;
  showForm: boolean;
  isSameAsAddress: boolean;
  allowAccess:Boolean;
  menuItemAllowAccess:boolean;

  selectedAddress: ApplicantAddress;
  sameAsOptions: ApplicantAddress[];

  addressTypes: NameValueDto[];
  propertyTypes: NameValueDto[];
  localities: NameValueDto[];
  roofTypes: NameValueDto[];
  floorings: NameValueDto[];
  standardOfLivings: NameValueDto[];
  
  countryList: NameValueDto[];
  stateList: NameValueDto[];
  districtList: NameValueDto[];
  cityList: NameValueDto[];
  emailFormat: ReferenceCode[];

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;


  constructor(private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private referenceCodeService: ReferenceCodeService,
    private _snackbar: MatSnackBar,
    private confirmationDialogService:ConfirmationDialogService,
    private validationService: ValidationService) {
      this.applicantAddressForm = this.formBuilder.group({
        addressType: '',
        sameAs: '',
        sameAsAddress: '',
        firstLine: '',
        secondLine: '',
        thirdLine: '',
        country: '',
        state: '',
        district:'',
        city: '',
        pinCode:'',
        landmark: '',
        mobileNumber: '',
        alternateNumber: '',
        landlineNumber: '',
        email:'',
        durationOfStay: '',
        propertyOwnership: '',
        locality: '',
        roofType: '',
        flooring: '',
        standardOfLiving: ''
      });
      this.allowAccess = this.applicationService.allowAccess;
  }

  ngOnInit() {
    this.getRefCodes();
    this.applyValidatorsToApplicantAddresses();
    this.getMenuItemAccess();

  }

  ngOnChanges(changes: SimpleChanges) {
    this.isFormDisabled = true;
    if (changes.applicant) {
      this.fetchApplicantAddresses();
    }
  }

  /*
   Applicant Address component, on loading executes this method to retrieve Reference Codes from server by passing classifier name,
    Reference Codes data will be retrieved for first time call and stored in cache, for next time reference code service give response from its cache 
   This Reference Codes  used to display dropdown values for ADDRESS TYPE,COUNTRY,STATE, DISTRICT,CITY,PROPERTY OWNERSHIP,LOCALITY,ROOF TYPE,STANDARD OF LIVING,FLOORING and etc
   These Reference Codes are case sensitive
  */
  getRefCodes() {
    this.referenceCodeService.getShortRefCodes('person_address_type').subscribe((response: any) => {
      this.addressTypes = response.person_address_type;
    });
    this.referenceCodeService.getShortRefCodes('premise_ownership').subscribe((response: any) => {
      this.propertyTypes = response.premise_ownership;
    });
    this.referenceCodeService.getShortRefCodes('residence_locality').subscribe((response: any) => {
      this.localities = response.residence_locality;
    });
    this.referenceCodeService.getShortRefCodes('residence_roof_type').subscribe((response: any) => {
      this.roofTypes = response.residence_roof_type;
    });
    this.referenceCodeService.getShortRefCodes('residence_floor_type').subscribe((response: any) => {
      this.floorings = response.residence_floor_type;
    });
    this.referenceCodeService.getShortRefCodes('residence_living_std').subscribe((response: any) => {
      this.standardOfLivings = response.residence_living_std;
    });
    // this.sameAsTypes = ['Permanent', 'Present'];
    this.referenceCodeService.getShortRefCodes('Country').subscribe((response: any) => {
      this.countryList = response.Country;
    });
    this.referenceCodeService.getRefCodesForClassifier('State').subscribe((response: any) => {
      this.stateList = response;
    });
    this.referenceCodeService.getRefCodesForClassifier('District').subscribe((response: any) => {
      this.districtList = response;
    });
    this.referenceCodeService.getRefCodesForClassifier('City').subscribe((response: any) => {
      this.cityList = response;
    });
    this.referenceCodeService.getRefCodesForClassifier('setting_dataformat').subscribe((response: any) => {
      this.emailFormat = response.filter(ele => ele.code.toUpperCase() === SETTING_DATAFORMAT_EMAIL_FORMAT);
    });
  }

   /*
   Applicant Address component, on loading executes this method to retrieve the Applicant Addresses from server by passing Application UID and Applicant UID,
    Based on server response, using "sameAsFlag" value, the 'updateSameAsOptions' function will be executed to update same as option,s
  */
  fetchApplicantAddresses() {
    this.showForm = false;
    this.applicationService.getApplicantAddresses(this.application.uid, this.applicant.uid)
      .subscribe((response: any) => {
        if (response) {
          this.applicantAddresses = response.personAddresses;
          this.applicantAddresses.forEach((element,index) => {
            this.applicantAddresses[index].address["deleteBtnDisabled"] = true;
          });

          this.showForm = (this.applicantAddresses && this.applicantAddresses.length > 0);
          this.buildAddressForm();
          this.updateSameAsOptions();
        }
      });
  }

  updateSameAsOptions() {
    this.sameAsOptions = this.applicantAddresses.filter(address =>
      !address.addressLink.sameAsFlag &&
      address.addressLink.uid !== this.selectedAddress.addressLink.uid);
  }

  buildAddressForm(applicantAddress?: ApplicantAddress, sameAsAddress?: Address) {
    if (this.showForm) {
      if (!applicantAddress){
        applicantAddress = this.applicantAddresses[0];
        this.applicantAddresses[0].address.deleteBtnDisabled = false;
      }
      let addressLink = applicantAddress.addressLink;
      let address = sameAsAddress ? sameAsAddress : applicantAddress.address;
      this.applicantAddressForm.patchValue({
        addressType:addressLink.linkedAs,
        sameAs: addressLink.sameAsFlag,
        sameAsAddress: addressLink.sameAsFlag ? addressLink.addressUid : null,
        firstLine: address.line1,
        secondLine: address.line2,
        thirdLine: address.line3,
        country: address.country,
        state: address.state,
        district: address.district,
        city: address.city,
        pinCode: address.postalCode,
        landmark: address.landmark,
        mobileNumber: addressLink.primaryPhone,
        alternateNumber: addressLink.alternatePhone,
        landlineNumber: addressLink.landlinePhone,
        email: addressLink.emailId,
        durationOfStay: addressLink.yearsInProperty,
        propertyOwnership: addressLink.ownershipType,
        locality: addressLink.localityType,
        roofType: addressLink.roofType,
        flooring: addressLink.flooringType,
        standardOfLiving: addressLink.livingStandardType
      });
      this.selectedAddress = applicantAddress;
      this.isSameAsAddress = this.selectedAddress.addressLink.sameAsFlag;
    }
    // this is default pattern for email.
    this.applicantAddressForm.controls['email'].setValidators([ControlValidators.patternValidator('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$', "Email does not match required format")]);
    // if emailFormat regex is present in field1 and regex is valid then we will validate email with respect to field1 regex pattern
    if (this.emailFormat && this.emailFormat[0].field1.trim() !== '' && this.validationService.isValidRegexPattern(this.emailFormat[0].field1)) {
      this.applicantAddressForm.controls['email'].setValidators([ControlValidators.patternValidator(this.emailFormat[0].field1.trim(), "Email does not match required format")]);
      this.applicantAddressForm.controls['email'].updateValueAndValidity()
    }
  }

  addNewAddress() {
    this.showForm = true;
      let newAddress = new ApplicantAddress();
      newAddress.address = new Address();
      newAddress.addressLink = new AddressLink();
      this.changeSelectedAddress(newAddress, undefined, "new");
      this.isFormDisabled = false;
  }

  changeSelectedAddress(applicantAddress?: ApplicantAddress, index?: number, from?: string) {
    this.showForm = true;
    this.applicantAddresses.forEach((element, i) => {
      this.applicantAddresses[i].address.deleteBtnDisabled = true;
    });
    if (from != "new") {
      this.applicantAddresses[index].address.deleteBtnDisabled = false;
    }

    this.buildAddressForm(applicantAddress);
    this.updateSameAsOptions();
  }

  /* These function will be executed on selecting of SAME AS dropdown value*/
  updateSameAsAddress() {
    if (this.applicantAddressForm.value.sameAs) {
      this.updateAddressFields(this.getSameAsAddress().address);
    }
  }

  sameAsCheckboxClicked() {
    if(this.applicantAddressForm.value.sameAs){
      this.isSameAsAddress = true;
      this.applicantAddressForm.patchValue({
        sameAsAddress: this.selectedAddress.addressLink.addressUid
      });
      this.sameAsDropdown.open();
      if(!this.selectedAddress.address.uid) {
        this.setAddressFields();
      }
    }
    else{
      this.isSameAsAddress = false;
      this.resetAddress();
    }
  }

  resetAddress() {
    if(!this.applicantAddressForm.value.sameAs){
      this.applicantAddressForm.patchValue({
        sameAsAddress: null,
        firstLine: null,
        secondLine: null,
        thirdLine: null,
        country:null,
        state: null,
        district: null,
        city: null,
        pinCode: null,
        landmark: null
      });
      this.updateAddressFields(this.selectedAddress.address);
    }
  }

  updateAddressFields(sameAsAddress: Address) {
    this.applicantAddressForm.patchValue({
      firstLine: sameAsAddress.line1,
      secondLine: sameAsAddress.line2,
      thirdLine: sameAsAddress.line3,
      country: sameAsAddress.country,
      state: sameAsAddress.state,
      district: sameAsAddress.district,
      city: sameAsAddress.city,
      pinCode: sameAsAddress.postalCode,
      landmark: sameAsAddress.landmark
    });
  }

  setAddressFields() {
    let applicantAddressFormFields = this.applicantAddressForm.value;
    this.selectedAddress.address.line1 = applicantAddressFormFields.firstLine;
    this.selectedAddress.address.line2 = applicantAddressFormFields.secondLine;
    this.selectedAddress.address.line3 = applicantAddressFormFields.thirdLine;
    this.selectedAddress.address.country = applicantAddressFormFields.country;
    this.selectedAddress.address.state = applicantAddressFormFields.state;
    this.selectedAddress.address.district = applicantAddressFormFields.district;
    this.selectedAddress.address.city = applicantAddressFormFields.city;
    this.selectedAddress.address.postalCode = applicantAddressFormFields.pinCode;
    this.selectedAddress.address.landmark = applicantAddressFormFields.landmark;
  }

  getSameAsAddress() {
    return this.applicantAddresses.find(applicantAddress => 
      applicantAddress.addressLink.addressUid ===
        this.applicantAddressForm.value.sameAsAddress);
  }

  saveAddress() {
    this.validationService.markFormGroupTouched(this.applicantAddressForm)
    let applicantAddressFormFields = this.applicantAddressForm.value;
    let addressUid = this.selectedAddress.address.uid ? this.selectedAddress.address.uid : "0";
    if (this.isFormDisabled) {
      return;
    }
  
    if (!this.applicantAddressForm.valid) {
        this._snackbar.open("Please enter all mandatory fields", "Close", { duration: 2000 });
        return;
    }
      if (!this.selectedAddress.addressLink.uid) {
        this.selectedAddress.addressLink.linkToType = 'person';
        this.selectedAddress.addressLink.linkToUid = this.applicant.uid;
        this.selectedAddress.addressLink.contextType = 'application';
        this.selectedAddress.addressLink.contextUid = this.application.uid;
      }
      this.selectedAddress.addressLink.linkedAs = applicantAddressFormFields.addressType;
      this.selectedAddress.addressLink.sameAsFlag = applicantAddressFormFields.sameAs ?
        applicantAddressFormFields.sameAs : false;
      this.selectedAddress.addressLink.primaryPhone = applicantAddressFormFields.mobileNumber;
      this.selectedAddress.addressLink.alternatePhone = applicantAddressFormFields.alternateNumber;
      this.selectedAddress.addressLink.landlinePhone = applicantAddressFormFields.landlineNumber;
      this.selectedAddress.addressLink.emailId = applicantAddressFormFields.email;
      this.selectedAddress.addressLink.yearsInProperty = applicantAddressFormFields.durationOfStay;
      this.selectedAddress.addressLink.ownershipType = applicantAddressFormFields.propertyOwnership;
      this.selectedAddress.addressLink.localityType = applicantAddressFormFields.locality;
      this.selectedAddress.addressLink.roofType = applicantAddressFormFields.roofType;
      this.selectedAddress.addressLink.flooringType = applicantAddressFormFields.flooring;
      this.selectedAddress.addressLink.livingStandardType = applicantAddressFormFields.standardOfLiving;
      if (this.isSameAsAddress) {
        let sameAsAddress = this.getSameAsAddress();
        this.selectedAddress.addressLink.sameasSource = sameAsAddress.addressLink.linkedAs;
        this.selectedAddress.addressLink.addressUid = sameAsAddress.addressLink.addressUid;
      } else {
        this.selectedAddress.addressLink.sameasSource = null;
        this.selectedAddress.addressLink.addressUid = null;
        delete this.selectedAddress.address.uid
        this.setAddressFields();
      }
      this.applicationService.saveApplicantAddress(this.application.uid,
        this.applicant.uid, addressUid, this.selectedAddress).toPromise().then(
          (_success) => {
            this._snackbar.open("Address updated successfully", "Close", {
              duration: 2000,
            });
            this.isFormDisabled = true;
            this.fetchApplicantAddresses();
          }, (failure) => {
            let errorMessage = []
            errorMessage.push("Address update failed.")
            errorMessage.push(failure)
            this.applicationService.displayErrorMessages(errorMessage);
          }
        );
  }

  enableDisableForm() {
    this.isFormDisabled = false;
  }

  cancelForm() {
    this.applicantAddresses.forEach((element, i) => {
      this.applicantAddresses[i].address.deleteBtnDisabled = true;
    });
    if (this.applicantAddresses && this.applicantAddresses.length > 0) {
      this.applicantAddresses[0].address.deleteBtnDisabled = false;
      this.buildAddressForm(this.applicantAddresses[0]);
    } else {
      this.showForm = false;
    }
    this.isFormDisabled = true;
  }
  //this function will apply validators to form group
  applyValidatorsToApplicantAddresses() {
    this.validationService.applyValidationRules(this.applicantAddressForm,"ApplicantAddresses").then((controlValidators) => {
      this.applicantAddressForm
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
            if(this.menuItemAccessSpecifier){
            if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
            }
          });
    }

    //this function will be called on click of delete icon on address
   deleteAddress(selectedAddress) {
    this.applicationService.addressDelete(this.application.uid, selectedAddress.addressLink.uid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("Address deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchApplicantAddresses();
    } else {
      let errorMessage = []
      errorMessage.push("Address deleted failed")
      errorMessage.push(response.body.message);
      this.applicationService.displayErrorMessages(errorMessage);
    }}, (failure) => {
      let errorMessage = []
      errorMessage.push("Address deleted failed")
      errorMessage.push(failure)
      this.applicationService.displayErrorMessages(errorMessage);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this address?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteAddress(this.selectedAddress)
      }
    })
  }

}