import SwaggerUI from 'swagger-ui';

import {AfterViewInit, Component, ElementRef} from '@angular/core';
import {ConfigureService} from '../../../../../../src/app/build-config/configure.service';
import {AuthTokenService} from '../../authentication/auth-token.service';
import { environment } from '../../../../../../src/environments/environment';

@Component({
  selector: 'ig-swagger',
  templateUrl: './swagger.component.html',
  styles: []
})
export class SwaggerComponent implements AfterViewInit {

  constructor(private el: ElementRef,
              private configService: ConfigureService,
              private tokenService: AuthTokenService) {
  }

  ngAfterViewInit(): void {
    const authToken = this.tokenService.getToken();
    /*const ui = SwaggerUI({});*/
    const ui = SwaggerUI({
      url: environment.API_URL + '/v2/api-docs',
      // url: 'http://petstore.swagger.io/v2/swagger.json',
      domNode: this.el.nativeElement.querySelector('.swagger-container'),
      apisSorter: 'alpha',
      jsonEditor: false,
      showRequestHeaders: false,
      deepLinking: true,
      displayOperationId: false,
      defaultModelsExpandDepth: 1,
      defaultModelExpandDepth: 1,
      defaultModelRendering: 'example',
      displayRequestDuration: false,
      docExpansion: 'none',
      filter: false,
      operationsSorter: 'alpha',
      showExtensions: false,
      tagsSorter: 'alpha',
      /* https://github.com/swagger-api/swagger-ui/issues/2915#issuecomment-348790440 */
      requestInterceptor:
        function (request) {
          request.headers['X-Auth-Token'] = authToken;
          return request;
        }
    });
  }

}
