import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OnDestroy } from "@angular/core";
import { Applicant } from "@app/applications/application-details/collateral/collateralDetails/collateral-details.model";
import { KycProof, DocumentVerificationDTO } from "@app/applications/application-details/business/kycProofs/kyc.model";
import { ReferenceCode } from "@app/admin/reference-code/reference-code.model";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { KYC_VERIFICATION_MODE_ELECTRONIC } from "@app/constants/data.constants";
@Component({
  selector: "eng-business-kyc-verification",
  templateUrl: "business-kyc-verification.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class BusinessKycVerificationComponent implements OnInit, OnDestroy {
  applicationUid: string;

  xDataServicesForm: FormGroup;
  selectedKycDocument: ReferenceCode;
  applicants: Applicant[];
  serviceProviderCode: string;
  businessKycProofs: KycProof[] = [];
  documentVerificationResponse: any;
  documentType: string;
  isShowResultCard: boolean;
  verificationDto: DocumentVerificationDTO = {};
  isCheckboxSelected: boolean;
  message: string;

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private referenceCodeService: ReferenceCodeService
  ) {
    this.xDataServicesForm = this.formBuilder.group({
      documentProof: ["", Validators.required],
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.xDataService;
        this.documentType = params.serviceType;
      });
  }

  ngOnInit() {
    this.getRefCodes();
  }

  // since in service type we will get the code of business_proof_doc to show the name of the particular code we are getting the value from refCode
  getRefCodes() {
    this.referenceCodeService
      .getRefCodesForClassifier("business_proof_doc")
      .subscribe((response: any) => {
        response.forEach((element) => {
          if (this.documentType.toLowerCase() == element.code.toLowerCase()) {
            this.selectedKycDocument = element;
            this.getApplicantKycProofs();
          }
        });
      });
  }


  onInitiateClick() {
    if (this.xDataServicesForm.valid) {
      this.verificationDto.isVerified = false;
      this.isCheckboxSelected = false;
      let document = this.xDataServicesForm.controls.documentProof.value;
      this.applicationService.businessKycVerify(this.applicationUid,document.linkToUid,document.kycProofDoc,document.uid)
        .toPromise().then(
          (_success) => {
            this.documentVerificationResponse = _success;
            if(this.documentVerificationResponse.status == 'VALID'){
              this.isShowResultCard = true;
              this._snackbar.open("Data retrieved successfully", "Close", {
                duration: 5000,
              });
            }
          },
          (failure) => {
            this.isShowResultCard = false;
            this._snackbar.open(failure, "close", {
              duration: 5000,
            });
          }
        );
    }
  }

  getApplicantKycProofs() {
    this.verificationDto.isVerified = false;
    this.applicationService.getKycProofs(this.applicationUid,this.selectedKycDocument.code)
      .subscribe((response: any) => {
        this.businessKycProofs = response.body;
        this.buildForm();
      });
  }

  buildForm() {
    // if there is no kycProof captured then we need to display error message
    if (this.businessKycProofs.length === 0) {
      this._snackbar.open(this.selectedKycDocument.name + " not available for business to validate.", "close",
        {
          duration: 8000,
        });
        this.message = this.selectedKycDocument.name + " not available for business to validate.";
      return;
    }
    // we need to show only kycProof which has document number hence we are filtering records from the list which does not have document number
    this.businessKycProofs = this.businessKycProofs.filter((proof) => proof.documentNumber);

    // if there is no document number present after filtering then we need to display error message
    if (this.businessKycProofs.length === 0) {
      this._snackbar.open(this.selectedKycDocument.name + " number is not available to validate.", "close",
        {
          duration: 8000,
        });
        this.message = this.selectedKycDocument.name + " number is not available to validate."
      return;
    }

    // if there is only one record in applicantKycProof then we are prePopulating the document number else user need to select document number from dropdown
    if (this.businessKycProofs.length === 1) {
      this.xDataServicesForm.patchValue({
        documentProof: this.businessKycProofs[0],
      });
    }

    if (this.businessKycProofs.length === 1 && this.businessKycProofs[0].verificationMode === KYC_VERIFICATION_MODE_ELECTRONIC) {
      this._snackbar.open(this.selectedKycDocument.name + " is already verified.", "close",
        {
          duration: 8000,
        });
        this.message = this.selectedKycDocument.name + " is already verified.";
      return;
    }
  }


  onVerifyClick() {
    if (!this.isCheckboxSelected) {
      this._snackbar.open("Checkbox must be ticked to confirm verification", "close", {
        duration: 5000,
      });
      return;
    }
    let documentUid = this.xDataServicesForm.controls.documentProof.value;
    this.verificationDto.isVerified = true;
    this.verificationDto.verificationMode = KYC_VERIFICATION_MODE_ELECTRONIC;
    this.verificationDto.jdata = this.documentVerificationResponse.serviceProviderData;
    this.applicationService
      .verifyKycDetails(this.applicationUid, documentUid.uid, this.verificationDto)
      .subscribe((response) => {
        if (response) {
          this._snackbar.open("Kyc verified", "close", {
            duration: 5000,
          });
          this.verificationDto = response.body;
        }
      });
  }

  ngOnDestroy(): void { }
}
