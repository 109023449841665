import { Injectable } from '@angular/core';
import { ReportLink } from '@app/reports/reports.model';
import { SecureStorageService} from '../../../projects/ig-core/src/lib/authentication/secureStorage.service';
import { LoanApplication, RecentApplicationsLocalDataComposite } from './applications.model';

@Injectable({
    providedIn: 'root'
})
export class RecentOpenedApplicationsService {
    

    constructor(private secureStorageService: SecureStorageService) {}

    addRecentApplication(recentAplication: LoanApplication){
        let recentApplicationsLocalDataComposite: RecentApplicationsLocalDataComposite = {};
        let recentOpenedApplications= this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications') ==undefined?new Array<RecentApplicationsLocalDataComposite>():this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications')
        recentOpenedApplications.forEach((element,index) => {
                if(element.application.uid === recentAplication.uid){
                    recentApplicationsLocalDataComposite = element;
                    recentOpenedApplications.splice(index,1)
                }
            });
            if(!recentApplicationsLocalDataComposite.application) {
                recentApplicationsLocalDataComposite.application = recentAplication
                recentApplicationsLocalDataComposite.reportInputParameters = [];
                recentApplicationsLocalDataComposite.checklistOptions = [];
            }
            
            recentOpenedApplications.unshift(recentApplicationsLocalDataComposite) 
            if(recentOpenedApplications.length> 6){
                recentOpenedApplications.splice(recentOpenedApplications.length-1,1)
            }
            this.secureStorageService.secureSessionStorage.setItem('recentOpenedApplications',recentOpenedApplications)      
        
    }

    getRecentOpenedApplications(){
        let dataArray= this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications') ==undefined?[]:this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications')
        return dataArray
    }

    addRecentSearchCriteria(newData){
        let oldArray= this.secureStorageService.secureSessionStorage.getItem('recentSearchCriteria') ==undefined?[]:this.secureStorageService.secureSessionStorage.getItem('recentSearchCriteria')
        let duplicate=false
        oldArray.forEach(element => {
            if(JSON.stringify(element) === JSON.stringify(newData) ){
                duplicate =true
            }
            
        });
       if(!duplicate){
        oldArray.unshift(newData) 
        if(oldArray.length>= 6){
            oldArray.splice(oldArray.length-1,1)
        }
        this.secureStorageService.secureSessionStorage.setItem('recentSearchCriteria',oldArray) 
       }
       
    }
    getRecentSearchCriteria(){
        let dataArray= this.secureStorageService.secureSessionStorage.getItem('recentSearchCriteria') ==undefined?[]:this.secureStorageService.secureSessionStorage.getItem('recentSearchCriteria')
        return dataArray
    }

    addReportInputParameter(recentReportData){
        let recentOpenedReport= this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications')
        if(recentReportData.contextUid !== null){
        let recentApplicationsLocalDataComposite: RecentApplicationsLocalDataComposite = this.getApplicationCompositeFromCache(recentReportData.contextUid, recentOpenedReport);
        if(recentApplicationsLocalDataComposite) {
            let reportInputParameters = recentApplicationsLocalDataComposite.reportInputParameters;
            let reportAlreadyExistsInReportInputParameters = false;
            reportInputParameters.forEach((element,index) => {
                if(element.uid === recentReportData.uid){
                    reportAlreadyExistsInReportInputParameters = true;
                    reportInputParameters[index] = recentReportData;
                }
            });
            if(!reportAlreadyExistsInReportInputParameters) {
                reportInputParameters.push(recentReportData);
            }
            this.secureStorageService.secureSessionStorage.setItem('recentOpenedApplications',recentOpenedReport) 
        }
    }
    }

    getApplicationCompositeFromCache(applicationUid, recentApplicationCompositeFromCache){
        for(let i=0;i<recentApplicationCompositeFromCache.length;i++) {
            let applicationComposite = recentApplicationCompositeFromCache[i];
            if(applicationComposite.application.uid === applicationUid){
                return applicationComposite;
            }
        }
    }

    getReportsRecentData(reportLink:ReportLink){
        let recentOpenedReport= this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications')
        if(reportLink.contextUid !== null){
        let recentApplicationsLocalDataComposite: RecentApplicationsLocalDataComposite = this.getApplicationCompositeFromCache(reportLink.contextUid,recentOpenedReport);
        if(recentApplicationsLocalDataComposite){
            let recentReportInputParameters;
            recentApplicationsLocalDataComposite.reportInputParameters.forEach(element => {
                if(element.uid === reportLink.uid){
                    recentReportInputParameters = element;
                }
        });
        return recentReportInputParameters;
        }
        }
    }

    addChecklistItem(checklistAnswer, contextUid) {
        let recentApplications = this.getRecentOpenedApplications();
        let recentApplicationsLocalDataComposite: RecentApplicationsLocalDataComposite =  this.getApplicationCompositeFromCache(contextUid,recentApplications)
        if(recentApplicationsLocalDataComposite) {
            let checklistOptions = recentApplicationsLocalDataComposite.checklistOptions;
            let checklistOptionsAlreadyExist = false;
            checklistOptions.forEach((element, index) => {
            if (checklistAnswer.uid === element.uid) {
                checklistOptionsAlreadyExist = true;
                checklistOptions[index] = checklistAnswer;
            }
            });

        if (!checklistOptionsAlreadyExist) {
            checklistOptions.push(checklistAnswer);
        }

        this.secureStorageService.secureSessionStorage.setItem('recentOpenedApplications', recentApplications);
        }
    }

    clearChecklistItems(contextUid){
        let recentApplications = this.getRecentOpenedApplications();
        let recentApplicationsLocalDataComposite: RecentApplicationsLocalDataComposite =  this.getApplicationCompositeFromCache(contextUid,recentApplications)
        recentApplicationsLocalDataComposite.checklistOptions = [];
        this.secureStorageService.secureSessionStorage.setItem('recentOpenedApplications', recentApplications);
    }
    

    getChecklistItems(contextUid, checkListItemsArray) {
        let savedChecklistOptions = this.secureStorageService.secureSessionStorage.getItem('recentOpenedApplications');
        let recentApplicationsLocalDataComposite = this.getApplicationCompositeFromCache(contextUid, savedChecklistOptions);
        
        if (recentApplicationsLocalDataComposite) {
          let checklistItemsMap = new Map(checkListItemsArray.map(ele => [ele.uid, ele]));
          
          let cachedChecklistOptions = recentApplicationsLocalDataComposite.checklistOptions.filter(element =>
            checklistItemsMap.has(element.uid)
          );
          
          return cachedChecklistOptions;
        }
      }

      entityCreatedDate(entityCreatedDate: Date){
        this.secureStorageService.secureSessionStorage.setItem('entityCreatedDate',entityCreatedDate)
      }

      getEntityCreatedDate(){
        let dataArray= this.secureStorageService.secureSessionStorage.getItem('entityCreatedDate')
        return dataArray
    }

    setSelectedDossier(entityCreatedDate: any){
        this.secureStorageService.secureSessionStorage.setItem('selectedUid',entityCreatedDate)
      }

      getSelectedDossier(){
        let dataArray= this.secureStorageService.secureSessionStorage.getItem('selectedUid')
        return dataArray
    }

}