import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ApplicationService } from "@app/applications/application.service";
import { MatSnackBar } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { componentDestroyed } from "@w11k/ngx-componentdestroyed";
import { OnDestroy } from "@angular/core";
import { Applicant } from "@app/applications/application-details/collateral/collateralDetails/collateral-details.model";
import { LINK_TO_TYPE_PERSON, USER_AGENT_WEB } from "@app/constants/data.constants";
import { AnalyzerRequestDTO, AnalyzerResponseDTO, BankAccountHolderComposite } from "@app/applications/application-details/business/businessBankAccount/business-bank-accounts.model";
import { ValidationService } from "@app/applications/application-validators/validation.service";
import { ControlValidators } from "@app/applications/application-validators/control-validators.directive";
import { ReferenceCodeService } from "@app/admin/reference-code/reference-code.service";
import { NameValueDto } from "@app/loan-od-accounts/name-value-dto";
import { Folder } from "@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model";
import { DossierDTO } from "@app/applications/application-details/financials/dossier/dossier.model";
@Component({
  selector: "eng-statement-analyzer",
  templateUrl: "statement-analyzer.template.html",
  styleUrls: [
    "../../../application-details.styles.scss",
    "../xdata-service.style.scss",
  ],
})
export class UploadedBankStatementAnalyzer implements OnInit, OnDestroy {
  applicationUid: string;

  bankDetailsForm: FormGroup;
  serviceProviderCode: string;
  bankStatementResponse: DossierDTO = {};
  applicants: Applicant[];
  dossierFiles: DossierDTO[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private activatedRoute: ActivatedRoute,
    private validationService: ValidationService,
    private referenceCodeService: ReferenceCodeService
  ) {
    this.bankDetailsForm = this.formBuilder.group({
      dossierFolder: ["", Validators.required]
    });
    this.activatedRoute.queryParams
      .pipe(takeUntil(componentDestroyed(this)))
      .subscribe((params) => {
        this.applicationUid = params.applicationUid;
        this.serviceProviderCode = params.xDataService;
      });
  }

  ngOnInit() {
    this.getDossierFolders();
  }

  getDossierFolders() {
    this.applicationService.getDossierFolders(this.applicationUid).subscribe(response => {
      this.dossierFiles = response.body
      this.buildForm();
    })
  }

  buildForm() {
    if (this.dossierFiles.length === 0) {
      this._snackbar.open("Dossier folder not available to initiate", "close",
        {
          duration: 8000,
        });
      return;
    }

    if (this.dossierFiles.length === 1) {
      this.bankDetailsForm.patchValue({
        dossierFolder: this.dossierFiles[0],
      });
    }
  }


  onInitiateClick() {
    this.validationService.markFormGroupTouched(this.bankDetailsForm)
    if (this.bankDetailsForm.valid) {
      let statmentdossieruid = this.bankDetailsForm.controls.dossierFolder.value.uid;
      this.applicationService.statementAnalyzerInitiate(this.applicationUid, statmentdossieruid).toPromise().then(
        (_success) => {
          this.bankStatementResponse = _success;
            this._snackbar.open("Bank statement analysis is successfully initiated on dossier " + this.bankDetailsForm.controls.dossierFolder.value.name, "Close", {
              duration: 8000,
            });
        },
        (failure) => {
          this._snackbar.open(failure, "Close", {
            duration: 8000,
          });
        }
      );
    }
  }

  ngOnDestroy(): void { }
}
