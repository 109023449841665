import { HttpClient, HttpParams, HttpResponse, HttpEvent, HttpRequest, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BusinessAddress } from '@app/applications/application-details/business/businessAddress/business-address.model';
import { Observable, Subject } from 'rxjs';
import { ApplicationSearchCriteria, LoanApplication, LoanApplicationComposite, LoanApplicationLite, EmiComputeCriteria } from './applications.model';
import { ApplicantAddress } from './application-details/applicant/applicantAddress/applicant-address.model';
import { BankAccounts, CFAStatus } from './applications.model'
import { ApplicantDocumentComposite } from './application-details/applicant/applicantDocuments/applicant-document.model';
import { ApplicantInformations } from './application-details/applicant/applicantInformation/applicant-information.model';
import { ApplicantKycProof, ApplicantKycProofType } from './application-details/applicant/applicantKycProofs/applicant-kyc-proof.model';
import { ApplicantReferencesComposite } from './application-details/applicant/applicantReference/applicant-reference.model';
import { BankAccount } from './application-details/business/businessBankAccount/business-bank-accounts.model';
import { BusinessDocumentComposite } from './application-details/business/businessDocuments/business-document.model';
import { BusinessInformation } from './application-details/business/businessInformation/business-information.model';
import { ReferenceBasic } from './application-details/business/businessReference/business-reference.model';
import { KycProof, KycProofType } from './application-details/business/kycProofs/kyc.model';
import { Applicant, ColletralDetails, ColletralDetailsComposite } from './application-details/collateral/collateralDetails/collateral-details.model';
import { ColletralSummaryComposite } from './application-details/collateral/collateralSummary/collateral-summary.model';
import { BusinessIncomeSummary, CFASummary, BusinessIncomeDetail, BusinessIncome, FamilyFinance, BusinessExpense } from './application-details/financials/financials.model';
import { LiabilityComposite } from './application-details/financials/liability/liability.model';
import { PreConditions } from './application-details/applicationDocument/preCondition/pre-condition.model';
import { PostDateCheques } from './application-details/applicationDocument/postDateCheque/post-date-cheque.model';
import { ApplicationCheckList } from './application-details/applicationDocument/application-document.model';
import { applicationTrail } from './application-details/decisions/decision.model';
import { BehaviorSubject, of } from 'rxjs';
import { DateFormatPipe } from '@app/utils/date-format.pipe';
import { AnyAaaaRecord } from 'dns';
import { MatSnackBar } from '@angular/material';
import { File, Folder, FolderFilesComposite } from '@app/applications/application-details/loanInformation/loan-folder-files/loan-folder-files.model'
import { environment } from '../../environments/environment';
import { BookLoanValidate } from './application-details/applicationDocument/book-loan/book-loan.model';
import { map, catchError } from 'rxjs/operators';
import { SnackbarService } from '@app/utils/multiple-snackbar-messages-display.service';
import { DeDupCheck, LmsDedupeMatch } from './application-details/dedup-check/dedup-check.model';
import { IncomeProfile } from './application-details/business/incomeProfile/income.model';
import { ReportLink } from '@app/reports/reports.model';
import { DataExportSearchCriteriaDTO } from '@app/reports-menu/dataExport/samunnatiExport/data-export.model';
import { DossierDTO } from './application-details/financials/dossier/dossier.model';
@Injectable({
    providedIn: 'root'
})
export class ApplicationService {
    private API = 'api/application';
    private APIv1 = 'api/v1/applications/';

    public allowAccess: boolean;
    constructor(private http: HttpClient,
        private _snackbar: MatSnackBar,
        private customSnackBarService: SnackbarService,
        private customDatepipe: DateFormatPipe) { }

    getApplications(pagingAndSortingCriteria: any,
        searchCriteria: ApplicationSearchCriteria): Observable<HttpResponse<LoanApplicationLite[]>> {
        let params = new HttpParams();
        params = params.set('page', pagingAndSortingCriteria.page);
        params = params.set('size', pagingAndSortingCriteria.size);
        params = params.set('sort', pagingAndSortingCriteria.sort);

        searchCriteria.applicationDateFrom = this.customDatepipe.transform(
            searchCriteria.applicationDateFrom, 'ISODATE');
        searchCriteria.applicationDateTo = this.customDatepipe.transform(
            searchCriteria.applicationDateTo, 'ISODATE');

        return this.http.post<any>(this.API + '/list', searchCriteria, { params, observe: 'response' });
    }

    getApplication(applicationUid: string) {
        return this.http.get<LoanApplication>(this.APIv1 + applicationUid, { observe: 'response' });
    }

    getBasicApplicationInfo(applicationUid: string, filter: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid);
        params = params.set('filter', filter);
        return this.http.get<LoanApplicationComposite>('api/getApplication', { params, observe: 'response' });
    }

    getBusinessAddresses(applicationUid: string) {
        return this.http.get<BusinessAddress[]>(this.API + '/' + applicationUid + '/address/business');
    }

    saveLoanApplication(application: LoanApplication): Observable<HttpResponse<any>> {
        return this.http.put<any>(this.APIv1 + application.uid +
            '/save', application, { observe: 'response' });
    }

    getBusinessBankAccounts(applicationUid: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid);
        return this.http.get<BankAccount>('api/fetchBankAccount', { params, observe: 'response' });
    }

    getBankAccountById(BankAccountUID) {
        return this.http.get<BusinessAddress[]>('/api/v1/bankAccount/' + BankAccountUID, { observe: 'response' });
    }

    getBusinessInformation(applicationUid: string, businessUid: string) {
        return this.http.get<BusinessInformation>(this.API + '/' + applicationUid +
            '/business/' + businessUid + '/businessList', { observe: 'response' });
    }

    getBusinessReference(applicationUid: string, businessUid: string) {
        return this.http.get<ReferenceBasic[]>(this.API + '/' + applicationUid +
            '/business/' + businessUid + '/references', { observe: 'response' });
    }

    getKycProofs(applicationUid: string, documentType: string) {
        let params = new HttpParams();
        if (documentType !== null) {
        params = params.set('documentType', documentType);
        }
        return this.http.get<KycProof>(this.APIv1 + applicationUid + '/business/kyc', { params, observe: 'response' });
    }

    getKycProofTypes(applicationUid: string, businessUid: string) {
        return this.http.get<KycProofType>(this.API + '/' + applicationUid + '/business/' + businessUid);
    }

    getApplicantBankAccounts(applicationUid: string, personUid: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid)
        params = params.set('personUid', personUid);
        return this.http.get<BankAccounts[]>('api/fetchApplicantBankAccount', { params, observe: 'response' });
    }

    getApplicantReference(applicationUid: string, personUid: string) {
        let params = new HttpParams();
        params = params.set('personUid', personUid);
        return this.http.get<ApplicantReferencesComposite[]>(this.API + '/' + applicationUid +
            '/person/' + personUid + '/personreference', { observe: 'response' });
    }

    getApplicantAddresses(applicationUid: string, personUid: string) {
        return this.http.get<ApplicantAddress>(this.API + '/' + applicationUid + '/address/person/' + personUid);
    }

    getApplicantKycProofs(applicationUid: string, personUid: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid);
        params = params.set('personUid', personUid)
        return this.http.get<ApplicantKycProof>('api/kyc-person', { params, observe: 'response' });
    }

    getKycProofsOnProofType(applicationUid: string, personUid: string,documentType:string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/' + documentType + '/kyc', { observe: 'response' });
    }

    verifyKycDetails(applicationUid: string, kycUid: string, verificationDTO) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/kyc/' + kycUid + '/verify', verificationDTO, { observe: 'response' });
    }

    getApplicantKycProofTypes(applicationUid: string, personUid: string) {
        return this.http.get<ApplicantKycProofType>(this.API + '/' + applicationUid + '/person/' + personUid);
    }

    getApplicantInformation(applicationUid: string, personUid: string) {
        return this.http.get<ApplicantInformations>('api/getApplicant/' + applicationUid + '/' + personUid);
    }

    saveBusinessBankAccount(applicationUid: string, businessUid: string,
        businessbankAccount: BankAccount): Observable<HttpResponse<any>> {
        if (businessbankAccount.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/business/'
                + businessUid + '/bankAccount/save', businessbankAccount, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/business/'
                + businessUid + '/bankAccount/save', businessbankAccount, { observe: 'response' });
        }
    }

    getCollateralDetails(applicationUid: string) {
        return this.http.get<ColletralDetailsComposite>(this.API + '/' + applicationUid + '/collateral');
    }

    getApplicants(applicationUid: string) {
        let params = new HttpParams();
        params = params.set('includeGuarantor', 'true');
        return this.http.get<Applicant>(this.API + '/' + applicationUid + '/applicants' , {params});
    }

    saveBusinessInformation(businessInformation: BusinessInformation,
        applicationUid: string, businessUid: string): Observable<HttpResponse<any>> {
        const businessInformationObject: any = Object.assign({}, businessInformation);
        businessInformationObject.businessEntity.dateOfIncorporation = this.customDatepipe.transform(
            businessInformationObject.businessEntity.dateOfIncorporation, 'ISODATE');
        return this.http.put<any>(this.API + '/' + applicationUid + '/business/' + businessUid + '/save',
            businessInformationObject, { observe: 'response' });
    }

    saveBusinessAddress(applicationUid: string, businessUid: string, addressUid: string,
        businessAddress: BusinessAddress): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + '/businesses/'
            + businessUid + '/addresses/' + addressUid + '/save', businessAddress, { observe: 'response' });
    }

    saveBusinessReference(applicationUid: string, businessUid: string, personUid: string,
        businessReference: ReferenceBasic): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + '/businesses/' + businessUid +
            '/references/' + personUid + '/save', businessReference, { observe: 'response' });
    }

    getApplicantDocuments(applicationUid: string, personUid: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid);
        params = params.set('personUid', personUid)
        return this.http.get<ApplicantDocumentComposite>('api/persons-documents', { params, observe: 'response' });
    }
    saveApplicantDocuments(applicationUid: string, applicantUid: string, savingDocumentOject) {
        if (savingDocumentOject.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/person/' + applicantUid + '/documents/'
                + savingDocumentOject.uid + '/save', savingDocumentOject, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + applicantUid + '/documents/'
                + 0 + '/save', savingDocumentOject, { observe: 'response' });
        }
    }

    getBusinessDocuments(applicationUid: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid)
        return this.http.get<BusinessDocumentComposite>('api/business-documents', { params, observe: 'response' });
    }
    saveBusinessDocuments(applicationUid: string, businessUid: string, savingDocumentOject) {
        if (savingDocumentOject.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/businesses/' + businessUid + '/documents/'
                + savingDocumentOject.uid + '/save', savingDocumentOject, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/businesses/' + businessUid + '/documents/'
                + 0 + '/save', savingDocumentOject, { observe: 'response' });
        }
    }

    getBusinessFinancials(applicationUid: string, financialType: string) {
        return this.http.get<BusinessIncomeSummary>(this.APIv1 + applicationUid +
            '/financials/businessincome/' + financialType);
    }

    getAssessmentYearAndMonths(applicationUid: string) {
        return this.http.get<BusinessIncomeSummary>(this.APIv1 + applicationUid +
            '/financials/yearmonths');
    }

    getCollateralSummary(applicationUid: string) {
        return this.http.get<ColletralSummaryComposite>(this.APIv1 + applicationUid + '/collateral' + '/0' + '/ltv');
    }

    getLiabilityDetails(applicationUid: string) {
        return this.http.get<LiabilityComposite>(this.API + '/' + applicationUid + '/liabilities');
    }

    savaLiabilityDetails(applicationUid: string, liabilities: LiabilityComposite) {
        if (liabilities.liability.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/liabilities/'
                + liabilities.liability.uid + '/save', liabilities, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/liabilities/'
                + 0 + '/save', liabilities, { observe: 'response' });
        }
    }

    getCfaDetails(applicationUid: string) {
        return this.http.get<CFASummary>(this.APIv1 + applicationUid
            + '/cfa', { observe: 'response' });
    }

    saveApplicantBankAccount(applicationUid: string, personUid: string,
        applicantbankAccount: BankAccounts): Observable<HttpResponse<any>> {
        if (applicantbankAccount.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/person/'
                + personUid + '/bankAccount/save', applicantbankAccount, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/person/'
                + personUid + '/bankAccount/save', applicantbankAccount, { observe: 'response' });
        }
    }

    saveApplicantReference(applicationUid: string, referenceForUid: string, personUid: string,
        applicantReference: ApplicantReferencesComposite): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + referenceForUid +
            '/references/' + personUid + '/save', applicantReference, { observe: 'response' });
    }

    saveApplicantAddress(applicationUid: string, personUid: string, addressUid: string,
        applicantAddress: BusinessAddress): Observable<HttpResponse<any>> {
        if (applicantAddress) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/person/'
                + personUid + '/addresses/' + addressUid + '/save', applicantAddress, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/person/'
                + personUid + '/addresses/' + addressUid + '/save', applicantAddress, { observe: 'response' });
        }
    }

    getCalculatedBusinessIncomeSummary(applicationUid: string, category: string,
        businessIncomeDetails: BusinessIncomeDetail[]) {
        return this.http.post<BusinessIncome[]>(this.APIv1 + applicationUid
            + '/financials/businessincome/' + category + '/summary',
            businessIncomeDetails, { observe: 'response' });
    }

    saveBusinessIncomeSummary(applicationUid: string, category: string,
        businessIncomeSummary: BusinessIncomeSummary) {
        businessIncomeSummary.businessIncomeDetail.forEach(businessIncomeDetail => {
            businessIncomeDetail.taxFilingDate = this.customDatepipe.transform(
                businessIncomeDetail.taxFilingDate, 'ISODATE');
        });
        return this.http.post<BusinessIncomeSummary>(this.APIv1 + applicationUid
            + '/financials/businessincome/' + category + '/save',
            businessIncomeSummary, { observe: 'response' });
    }

    //get business expanses details api
    getBusinessExpenses(applicationUid: string) {
        return this.http.get<BusinessExpense[]>('api/v1/application/' + applicationUid
            + '/financials/businessexpense', { observe: 'response' });
    }

    saveApplicantInformation(applicantInformations: ApplicantInformations,
        applicationUid: string, personUid: string): Observable<HttpResponse<any>> {
        let type = "applicant";
        const applicantInformationObject: any = Object.assign({}, applicantInformations);
        applicantInformationObject.person.dateOfBirth = this.customDatepipe.transform(
            applicantInformationObject.person.dateOfBirth, 'ISODATE');
        return this.http.put<any>(this.APIv1 + applicationUid + '/person/'
            + personUid + '/' + "applicant" + '/save', applicantInformations, { observe: 'response' });
    }

    getFamilyIncome(applicationUid: string) {
        return this.http.get<FamilyFinance[]>(this.APIv1 + applicationUid + '/financials/familyincome', { observe: 'response' });
    }

    getFamilyExpenses(applicationUid: string) {
        return this.http.get<FamilyFinance[]>(this.APIv1 + applicationUid + '/financials/familyexpense', { observe: 'response' });
    }

    saveKycProof(applicationUid: string, businessUid: string, kycUid: string,
        kycProof: KycProof): Observable<HttpResponse<any>> {
        kycProof.documentExpiryDate = this.customDatepipe.transform(
            kycProof.documentExpiryDate, 'ISODATE');
        return this.http.post<any>(this.APIv1 + applicationUid + '/businesses/' + businessUid +
            '/kycproofs/' + kycUid + '/save', kycProof, { observe: 'response' });
    }
    saveApplicantKycProof(applicationUid: string, applicantUid: string, kycUid: string,
        kycProof: KycProof): Observable<HttpResponse<any>> {

        if (kycProof.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/person/' + applicantUid +
                '/kycproofs/' + kycUid + '/save', kycProof, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + applicantUid +
                '/kycproofs/' + 0 + '/save', kycProof, { observe: 'response' });
        }
    }
    getDeviations(applicationUid: string) {
        return this.http.get<any>('api/applications/' + applicationUid
            + '/deviations', { observe: 'response' });
    }
    saveCamDeviations(applicationUid: string, deviations) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/deviations/save', deviations
            , { observe: 'response' });
    }
    //CAM conditions related api's
    getConditions(applicationUid: string, type) {
        return this.http.get<any>('api/applications/' + applicationUid
            + '/conditions', { observe: 'response' });
    }
    saveCamCondition(applicationUid: string, conditions) {
        return this.http.post<any>('api/applications/' + applicationUid + '/conditions/save', conditions
            , { observe: 'response' });
    }

    //Decision related api's
    saveDecision(applicationUid: string, trackingId: string,
        applicationDecision: string, returnStage: any,rejectReason: any, remarks: string, currentWorkflowStage: string) {
            let decisionChangeRequest = {}
            decisionChangeRequest = {
                'trackingId': trackingId,
                'returnStage': returnStage,
                'remarks': remarks,
                'currentStage': currentWorkflowStage,
                'rejectReason': rejectReason,
            }

        return this.http.post<any>(this.APIv1 + applicationUid + '/decisions/'
            + applicationDecision, decisionChangeRequest, { observe: 'response' });
    }

    getDecisions(applicationUid: string) {
        return this.http.get<any>('api/v1/applications/' + applicationUid
            + '/decisionitems', { observe: 'response' });
    }



    saveDecisionItems(applicationUid: string, decisionItems): Observable<HttpResponse<any>> {
        const decisionItemsObject: any = Object.assign({}, decisionItems);
        // decisionItemsObject.documentExpiryDate = this.customDatepipe.transform(
        //     kycProof.documentExpiryDate);
        return this.http.put<any>(this.APIv1 + applicationUid + "/decisionitems/save", decisionItemsObject);
    }

    saveCollateralDetails(applicationUid: string, collateraluid: string,
        collaterals: ColletralDetails): Observable<HttpResponse<any>> {
        if (collaterals.collateral.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/collateral/' + collateraluid + '/save', collaterals, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/collateral/' + "0" + '/save', collaterals, { observe: 'response' });
        }
    }

    bookLoan(applicationUid: string) {
        return this.http.post<any>(this.APIv1 + applicationUid +
            '/actions/loanbook', { observe: 'response' });
    }

    fillAccount(applicationUid: string, LoanBookFillAccount: any) {
        LoanBookFillAccount.accountOpenDate = this.customDatepipe.transform(LoanBookFillAccount.accountOpenDate, 'ISODATE');
        return this.http.post<any>(this.APIv1 + applicationUid +
            '/fillaccount', LoanBookFillAccount,  { observe: 'response' });
    }

    getPreConditions(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/conditions/' + "pre" + '/fulfillmentitems', { observe: 'response' });
    }

    savePreconditions(applicationUid: string, preConditionsItems): Observable<HttpResponse<any>> {
        const preConditionObject: any = Object.assign({}, preConditionsItems);
        return this.http.put<any>(this.APIv1 + applicationUid + '/conditions/' + "pre" + '/fulfillmentitems/save', preConditionObject);
    }

    saveBusinessExpenses(applicationUid: string,
        businessExpenses: BusinessExpense[]): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + "/financials/businessExpense/"
            + "save", businessExpenses, { observe: 'response' });
    }

    saveFamilyExpenses(applicationUid: string,
        familyExpenses: FamilyFinance[]): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + "/financials/familyExpense/"
            + "save", familyExpenses, { observe: 'response' });
    }

    //BankStatements related api's
    getBankStatements(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + "/financials/businessincome/bankstatement", { observe: 'response' });
    }
    saveBankStatements(applicationUid: string, bankaccountuid: string,
        bankstatements, newItemSave): Observable<HttpResponse<any>> {
        if (newItemSave) {
            return this.http.post<any>(this.APIv1 + applicationUid + '/financials/bankstatements/' + bankaccountuid + '/save', bankstatements, { observe: 'response' });
        } else {
            return this.http.put<any>(this.APIv1 + applicationUid + '/financials/bankstatements/' + bankaccountuid + '/save', bankstatements, { observe: 'response' });
        }
    }
    bankStatementComputeSummary(applicationUid: string, bankAcccountUid: string, bankstatements) {
        return this.http.post<any>(this.APIv1 + applicationUid + "/financials/bankstatements/" + bankAcccountUid + "/summary", bankstatements, { observe: 'response' });
    }

    getPostDateCheque(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/postdatecheques', { observe: 'response' });
    }

    getFeesForApplication(applicationUid: string) {
        let params = new HttpParams();
        params = params.set('category', "Fees");
        return this.http.get<any>(this.APIv1 + applicationUid + '/feesAndCharges', { params, observe: 'response' });
    }

    savePdcComponent(applicationUid: string, pdcUid: string,
        pdcDTO: PostDateCheques): Observable<HttpResponse<any>> {
            const pdcObject: any = Object.assign({}, pdcDTO);
            pdcObject.chequeDate = this.customDatepipe.transform(
                pdcObject.chequeDate, 'ISODATE');
        if (pdcObject.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/postdatecheques/' + pdcUid + '/save', pdcObject, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/postdatecheques/' + "0" + '/save', pdcObject, { observe: 'response' });
        }
    }

    saveFeesAndCharge(applicationUid: string, pdcUid: string,
        pdcDTO: PostDateCheques): Observable<HttpResponse<any>> {
            const pdcObject: any = Object.assign({}, pdcDTO);
            pdcObject.chequeDate = this.customDatepipe.transform(
                pdcObject.chequeDate, 'ISODATE');
        if (pdcObject.uid) {
            return this.http.post<any>(this.APIv1 + applicationUid + '/feesAndCharges/' + pdcUid + '/save', pdcObject, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/feesAndCharges/' + "0" + '/save', pdcObject, { observe: 'response' });
        }
    }

    getCheckListTemplates() {
        return this.http.get<any>('api/v1/masters/checklisttemplates', { observe: 'response' });
    }

    getCheckListTemplateItems(templateUid: string) {
        return this.http.get<any>('api/v1/resources/checklisttemplates/' + templateUid + '/templateitems', { observe: 'response' });
    }

    getApplicationCheckLists(applicationUid: string) {
        return this.http.get<ApplicationCheckList>(this.APIv1 + applicationUid + '/checklists', { observe: 'response' });
    }

    getNewCheckListTemplteItems(applicationUid: string, templateuid: string) {
        return this.http.get<ApplicationCheckList>(this.APIv1 + applicationUid + '/checklists/new/' + templateuid, { observe: 'response' });
    }

    saveFamilyIncomes(applicationUid: string,
        familyIncomes: FamilyFinance[]): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + applicationUid + "/financials/familyIncome/"
            + "save", familyIncomes, { observe: 'response' });
    }



    initiateWorkflow(applicationUid: string) {
        return this.http.post<any>(this.APIv1 + applicationUid +
            '/workflow/start', { observe: 'response' });
    }

    getApplicationQuestionnairebyCode(applicationUid: string, questionnaireCode: string) {
        let params = new HttpParams();
        params = params.set('applicationUid', applicationUid);
        params = params.set('questionnaireCode', questionnaireCode);
        return this.http.get<any>("api/questionnaire-responses/application", { params, observe: 'response' });
    }

    getquestionnairebyCode(questionnaireCode: string) {
        let params = new HttpParams();
        params = params.set('questionnaireCodes', questionnaireCode);
        return this.http.get<any>("api/questionnaires/active", { params, observe: 'response' });
    }

    saveApplicationquestionnaireAnswers(answers) {
        return this.http.post<any>("api/questionnaire-responses/detailed-save", answers, { observe: 'response' });
    }

    getQuestionPrefilledDataforApplication(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/camdataset', { observe: 'response' });
    }

    saveCheckListResponse(applicationUid: string, checklists) {
        if (checklists.checklist.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/checklists/' + checklists.checklist.uid + '/save', checklists, { observe: 'response' });

        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/checklists/' + 0 + '/save', checklists, { observe: 'response' });
        }
    }

    getCalculatedEmi(applicationUid: string, emiComputeCriteria: EmiComputeCriteria) {
        return this.http.post<any>(this.APIv1 + applicationUid
            + '/emi', emiComputeCriteria, { observe: 'response' });
    }

    getApplicationAccessModifiers(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid
            + '/workitems', { observe: 'response' });
    }

    getPostApprovalChangeRequests(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/postapprchanges', { observe: 'response' });
    }

    getWorkflowReturnStages(applicationUid: string) {
        let type = "type";
        return this.http.get<string[]>(this.APIv1 + applicationUid
            + '/workflowstages/' + type, { observe: 'response' });
    }

    savePostApprovelChangeRequests(applicationUid: string, changeRequests) {
        if (changeRequests.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/postapprchanges/' + changeRequests.uid + '/save', changeRequests, { observe: 'response' });

        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/postapprchanges/' + 0 + '/save', changeRequests, { observe: 'response' });
        }
    }

    getRepaymentAccount(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/repaymentaccount', { observe: 'response' });
    }

    saveRepaymentAccount(applicationUid: string, account) {
        return this.http.put<any>(this.APIv1 + applicationUid + '/repaymentaccount/save', account, { observe: 'response' });
    }

    // api to save guarantor
    saveGuarantor(applicationUid: string, guarantorInformation) {
        return this.http.put<any>(this.APIv1 + applicationUid + '/guarantor/save', guarantorInformation, { observe: 'response' });
    }

    getApplicationWorkflowTrails(applicationUid: string) {
        return this.http.get<applicationTrail[]>(this.APIv1 + applicationUid + '/workflowtrail', { observe: 'response' });
    }

    getCfaStatus(applicationUid: string) {
        return this.http.get<CFAStatus>(this.APIv1 + applicationUid + '/financials/cfa/status', { observe: 'response' });
    }

    finalizeCfa(applicationUid: string, businessIncomeUid: string) {
        return this.http.get<CFASummary>(this.APIv1 + applicationUid
            + '/financials/cfa/freeze/' + businessIncomeUid, { observe: 'response' });
    }

    reviseCfa(applicationUid: string, businessIncomeUid: string) {
        return this.http.get<CFASummary>(this.APIv1 + applicationUid
            + '/financials/cfa/revise/' + businessIncomeUid, { observe: 'response' });
    }

    getCollateralStatus(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/collateral/status', { observe: 'response' });
    }

    finalizeCollateral(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid
            + '/collateral/' + 0 + '/finalize', { observe: 'response' });
    }

    reviseCollateral(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid
            + '/collateral/' + 0 + '/unfreeze', { observe: 'response' });
    }

    getApplicationState(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/state', { observe: 'response' });
    }

    validateApplication(application: LoanApplication): Observable<HttpResponse<any>> {
        return this.http.post<any>(this.APIv1 + application.uid +
            '/validate', application, { observe: 'response' });
    }

    //Change Approval Api's
    getChangeApproval(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/changeitems', { observe: 'response' });
    }

    saveChangeApproval(applicationUid: string, changeApprovals) {
        return this.http.put<any>(this.APIv1 + applicationUid + '/changeitems/save', changeApprovals, { observe: 'response' });

    }

    //this function is uesd to display error message when api's call gives 400,500
    displayErrorMessages(mesg?: any, componentMesg?: string, type?: string) {
        this.customSnackBarService.openSnackBar(mesg, 'Close');
        // let errorMessage = "";
        // if(_failure.status === 400) {
        //   errorMessage = _failure.error.message;
        // } else if(_failure.status === 500) {
        //     errorMessage = componentMesg + " Internal Server Error. Please contact system administrator";
        // } else if(type== "warning"){
        //     errorMessage = componentMesg;
        // } else {
        //   errorMessage = componentMesg + " Error processing data in server. Please contact system administrator";
        // }
        // this._snackbar.open("FAILURE: " + errorMessage, "Close", {
        //     duration: 10000,
        // });
    }


    //application folder and file related api's
    getApplicationFolders(applicationUid: string) {
        return this.http.get<Folder[]>(this.APIv1 + applicationUid + '/folders', { observe: 'response' });
    }

    getFinancialFolders(applicationUid: string, personUid: string) {
        return this.http.get<Folder[]>(this.APIv1 + applicationUid + '/person/' + personUid + '/financials', { observe: 'response' });
    }

    saveOrUpdateApplicationFolder(applicationUid: string, folderObject) {
        if (folderObject.uid) {
            return this.http.put<Folder>(this.APIv1 + applicationUid + '/folders/' + folderObject.uid + '/save', folderObject, { observe: 'response' });

        } else {
            return this.http.post<Folder>(this.APIv1 + applicationUid + '/folders/' + 0 + '/save', folderObject, { observe: 'response' });
        }
    }

    getAllFilesInApplicationFolder(applicationUid: string, folderUID: string) {
        if(folderUID){
        return this.http.get<FolderFilesComposite>(this.APIv1 + applicationUid + '/folders/' + folderUID + '/files', { observe: 'response' });
    }
    }

    getAllFilesInFinancialFolder(applicationUid: string, personUid: string, financialUid: string) {
        if(financialUid){
        return this.http.get<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/financials/' + financialUid + '/files', { observe: 'response' });
    }
    }

    downloadFileFromApplicationFolder(applicationUid: string, folderUID: string, fileID: string) {
        // return this.http.get(this.APIv1 + applicationUid + '/folders/' + folderUID + '/files/' + fileID + '/download', { observe: 'response',responseType: 'blob' });
        return this.http.get(this.APIv1 + applicationUid + '/folders/' + folderUID + '/files/' + fileID + '/download', { observe: 'response', responseType: 'blob' })
            .pipe(map((res: HttpResponse<Blob>) => {
                const out = { data: null, filename: null, type: null };
                out.data = res.body;
                return out;
            }));
    }

    // Docket related api's
    // api to get the docket folder names in first card
    getDocketFolders(applicationUid: string) {
        return this.http.get<Folder[]>(this.APIv1 + applicationUid + '/dockets', { observe: 'response' });
    }

    // api to create folder when click of ok button
    saveOrUpdateDocketFolder(applicationUid: string, docketObject) {
        if (docketObject.uid) {
            return this.http.put<Folder>(this.APIv1 + applicationUid + '/dockets/' + docketObject.uid + '/save', docketObject, { observe: 'response' });

        } else {
            return this.http.post<Folder>(this.APIv1 + applicationUid + '/dockets/' + 0 + '/save', docketObject, { observe: 'response' });
        }
    }

    // api to add to docket folder from document screen (from report-criteria.component.ts)
    addToDocket(applicationUid: string, docketUid: string, reportLink: ReportLink){
        return this.http.post<any>(this.APIv1 + applicationUid + '/dockets/' + docketUid + '/addToDocket', reportLink, { observe: 'response' });
    }

    // to get the uploaded documents or images
    getDocketFiles(applicationUid: string, docketUid: string) {
        if(docketUid){
        return this.http.get<FolderFilesComposite>(this.APIv1 + applicationUid + '/dockets/' + docketUid + '/files', { observe: 'response' });
    }
    }

    // this is to genereate the final merged document where we will get one pdf in combined form
    generateMergeReport(applicationUid: string, docketUid: string) {
        return this.http.post(this.APIv1 + applicationUid + '/dockets/' + docketUid + '/generate', { observe: 'response'})
    }
    

    downloadFileFromDocketFolder(applicationUid: string, docketUid: string, fileID: string) {
        // return this.http.get(this.APIv1 + applicationUid + '/folders/' + folderUID + '/files/' + fileID + '/download', { observe: 'response',responseType: 'blob' });
        return this.http.get(this.APIv1 + applicationUid + '/dockets/' + docketUid + '/file/' + fileID + '/download', { observe: 'response', responseType: 'blob' })
            .pipe(map((res: HttpResponse<Blob>) => {
                const out = { data: null, filename: null, type: null };
                out.data = res.body;
                return out;
            }));
    }

    //this service will accept Array of file with multipart format 
    //so all file's are append to formdata with key "documents"
    uploadFileInApplicationFolder(applicationUid: string, folderUID: string, files, folderType: string): Observable<HttpEvent<any>> {
        const formData = new FormData();
        const filesArray = files
        for (let i = 0; i < filesArray.length; i++) {
            let file = filesArray[i]
            formData.append('documents', file)
        }
        
        const newRequest = new HttpRequest('POST', environment.API_URL + '/' + this.APIv1 + applicationUid + '/' + folderType + '/' + folderUID + '/files/upload', formData);
        return this.http.request(newRequest);
    }

    // api to edit image in applicant and co-applicant 
    uploadBorrowerProfilePicture(applicationUid: string, applicantUid: string, files) {
        const formData = new FormData();
            formData.append('image', files)

        return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + applicantUid + '/profilePicture', formData, { observe: 'response'})
    }

    loanBookValidate(applicationUid: string) {
        return this.http.get<BookLoanValidate>(this.APIv1 + applicationUid + '/actions/loanbook/validate', { observe: 'response' });
    }

    getFillAccount(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/fillaccount', { observe: 'response' });
    }

    getApplicationChangeTrails(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/allchangeitems', { observe: 'response' });
    }

    getPostApprovalChangeRecord(applicationUid: string, value: any) {
        let params = new HttpParams();
        params = params.set('approved', value);
        return this.http.get<any>(this.APIv1 + applicationUid + '/postapprchanges', { params, observe: 'response' });
    }


    //this service will get the details of applicant/coapplicant bureau reports
    getPersonBureauReports(applicationUid: string, personUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/bureaureports', { observe: 'response' });
    }

    getXDataServices() {
        return this.http.get<any>('api/v1/xdata-service', { observe: 'response' });
    }

    getReportScore(applicationUid: string, party:string, partyUid: string,reportType: string) {
        return this.http.get<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/' + party + '/' + partyUid + '/bureaureport/'+ reportType , { observe: 'response' });
    }

    initiateEsign(applicationUid: string, loanDocumentUid: string) {
        return this.http.post<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/loanDocuments/' + loanDocumentUid + '/esign/initiate' , { observe: 'response' });
    }

    getVerifiedDocument(applicationUid: string, partyUid: string,idProofType: string, idProofUid:string) {
        return this.http.get<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/persons/' + partyUid + '/kyc/verify/'+ idProofType + '/' + idProofUid , { observe: 'response' });
    }

    saveapplicantBureauReports(applicationUid: string, personUid: string, savingBureauReportObject) {
        if (savingBureauReportObject.uid) {
            return this.http.put<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/bureaureports/'
                + savingBureauReportObject.uid + '/save', savingBureauReportObject, { observe: 'response' });
        } else {
            return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/bureaureports/'
                + 0 + '/save', savingBureauReportObject, { observe: 'response' });
        }
    }

    unLockApplication(applicationUid: string, applicationUnlockRequest) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/unlock', applicationUnlockRequest, { observe: 'response' });
    }

    reviseFinalApproval(applicationUid: string) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/reset/finalapproval', { observe: 'response' });
    }
    reviseProvisionalApproval(applicationUid: string) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/reset/provisionalapproval', { observe: 'response' });
    }

    revertSingleApproval(applicationUid: string, postApprovalChangeId: string) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/revert/postApprovalChange/' + postApprovalChangeId , { observe: 'response' });
    }

    // this function used to convert file size in "MB or KB"
    bytesToSize(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    reviseDSR(applicationUid: string, businessIncomeUid: string, dsrRequest) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/financials/cfa/revisedsr/' + businessIncomeUid, dsrRequest, { observe: 'response' });
    }

    collateralDelete(applicationUid: string, collateralUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/collateral/' + collateralUid + '/delete', { observe: 'response' });
    }

    documentDelete(applicationUid: string, documentUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/documents/' + documentUid + '/delete', { observe: 'response' });
    }

    kycDelete(kycUid: string) {
        return this.http.delete<any>('api/v1/kyc/' + kycUid + '/delete', { observe: 'response' });
    }

    referenceDelete(applicationUid: string, referenceForUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/references/' + referenceForUid + '/delete', { observe: 'response' });
    }

    fileDelete(applicationUid: string, fileUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/files/' + fileUid + '/delete', { observe: 'response' });
    }

    conditionDelete(applicationUid: string, conditionUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/conditions/' + conditionUid + '/delete', { observe: 'response' });
    }

    deviationDelete(applicationUid: string, deviationUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/deviations/' + deviationUid + '/delete', { observe: 'response' });
    }

    bureaureportsDelete(applicationUid: string, bureaureportUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/bureaureports/' + bureaureportUid + '/delete', { observe: 'response' });
    } 

    addressDelete(applicationUid: string, addresslinkuid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/addresses/links/' + addresslinkuid + '/delete', { observe: 'response' });
    } 

    bankAccountDelete(applicationUid: string, bankAccountUid: string){
        return this.http.delete<any>(this.APIv1 + applicationUid + '/bankaccounts/' + bankAccountUid + '/delete', { observe: 'response' });
    }
    getApplicationOTP(applicationUid: string,requestparams){
        let params = new HttpParams();
        params = params.set('requestBy', requestparams.requestBy);
        params = params.set('requestNode', requestparams.requestNode);
        params = params.set('requestApp', requestparams.requestApp);
        params = params.set('requestLat', requestparams.requestLat);
        params = params.set('requestLng', requestparams.requestLng);
        return this.http.get<any>('api/v1/utils/OTP/applications/'+ applicationUid, { params,observe: 'response' });
    }

    getOTPConfirmation(applicationUid: string,requestparams){
        let params = new HttpParams();
        params = params.set('usedBy', requestparams.usedBy);
        params = params.set('usedNode', requestparams.usedNode);
        params = params.set('usedApp', requestparams.usedApp);
        params = params.set('usedLat', requestparams.usedLat);
        params = params.set('usedLng', requestparams.usedLng);
        params = params.set('otp', requestparams.otp);
        return this.http.get<any>('api/v1/utils/OTP/applications/'+ applicationUid+'/confirm', {params, observe: 'response' });
    }

    getDeDupDetails(applicationUid: string,deDuptype:string,deDuptypeUID:string){
        //here deDuptype is from which component we are calling,example business,person,
        // here deDuptypeUID values will come as personUID or businessUID
        return this.http.get<DeDupCheck[]>(this.APIv1 + applicationUid + '/'+deDuptype+'/' + deDuptypeUID + '/dedup', { observe: 'response' });
    }

    getDeDupDetailsFromLms(applicationUid: string,deDuptype:string,deDuptypeUID:string){
        //here deDuptype is from which component we are calling,example business,person,
        // here deDuptypeUID values will come as personUID or businessUID
        return this.http.get<LmsDedupeMatch[]>(this.APIv1 + applicationUid + '/'+deDuptype+'/' + deDuptypeUID + '/lmsdedupe', { observe: 'response' });
    }
    getEVerifyDetails(applicationUid:string,personUid:string,kycUid:string){
        return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + personUid+'/kyc/'+kycUid+'/e-verify', { observe: 'response' });
    }

    updateApplicationRO(applicationUid:string, applicationUpdateDto: any){
        return this.http.put<any>(this.APIv1 + applicationUid + '/properties', applicationUpdateDto, { observe: 'response' });
    }
    
    addnewCoapplicant(applicationUid: string, personUid: any, files,personDTO:any, partyPlay:any): Observable<HttpEvent<any>> {

        const applicantInformationObject: any = Object.assign({}, personDTO);
        applicantInformationObject.person.dateOfBirth = this.customDatepipe.transform(
            applicantInformationObject.person.dateOfBirth, 'ISODATE');
        const formData = new FormData();
        const filesArray = files
        for (let i = 0; i < filesArray.length; i++) {
            let file = filesArray[i]
            formData.append('images', file)
        }
              const blobOverrides = new Blob([JSON.stringify(applicantInformationObject)], {
                type: 'application/json',
              });
            formData.append('person', blobOverrides)
        //}
        let params = new HttpParams();
        params = params.set('partyPlay', partyPlay)
        const newRequest = new HttpRequest('POST', environment.API_URL + '/' + this.APIv1 + applicationUid + '/person/' + personUid + '/save', formData, {params});
        return this.http.request(newRequest);
        
    }

    getscoreCardDetails(applicationUid:string){
        return this.http.get<any>(this.APIv1 + applicationUid + '/scorecard', { observe: 'response' });
    }

        //Display engrow Infomarion and encore Information
    getEngrowEncoreInfo(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/actions/borrowers/validate', { observe: 'response' });
    }

    calculate(applicationUid: string, incomeProfileUid: string, party:string, partyUid:string,  userAgent:string,incincomeProfile:IncomeProfile) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/' + party + '/' + partyUid + '/incomeprofiles/' + incomeProfileUid + '/' + userAgent + '/compute', incincomeProfile ,  { observe: 'response' });
    }

    getTemplate(applicationUid: string, party:string, partyUid:string,  incomeProfileType: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/' + party + '/' + partyUid + '/incomeprofile/' + incomeProfileType + '/template' ,  { observe: 'response' });
    }

    saveIncomeProfile(applicationUid: string, party:string, partyUid:string, incincomeProfile:IncomeProfile) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/' + party + '/' + partyUid + '/incomeprofile'  + '/save', incincomeProfile ,  { observe: 'response' });
    }
    getIncomeProfile(applicationUid: string, party:string, partyUid:string, incomeProfileUid:string) {
        return this.http.get<IncomeProfile[]>(this.APIv1 + applicationUid + '/' + party + '/' + partyUid + '/incomeprofiles/' + incomeProfileUid , { observe: 'response' });
    }

    processIncomeProfile(applicationUid: string, party:string, partyUid:string, incomeProfileUid: string, userAgent:string,  incincomeProfile:IncomeProfile) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/' + party + '/' + partyUid + '/incomeprofiles/' + incomeProfileUid  + '/' + userAgent + '/process', incincomeProfile ,  { observe: 'response' });
    }

    eKycRequest(applicationUid:string,request){
        if(request.email== ''){
            delete request.email
        }
        if(request.mobileNumber== ''){
            delete request.mobileNumber
        }
        return this.http.post<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/ekyc/initiate',request, {observe: 'response' }).pipe(
            map(result => {
              return result;
            }),
        );
    }
    eKycDataRetrieve(applicationUid:string,requestId:string, partyPlay:string){
        let params = new HttpParams();
        params = params.set('partyPlay', partyPlay)
        return this.http.get<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/ekyc/' + 'retrieve/' + requestId , { params, observe: 'response' }).pipe(
            map(result => {
              return result;
            }),
        );
    }

    // api to get coApplicant data for guarantor
    getCoApplicants(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/guarantor', { observe: 'response' });
    }

    // api to get coLender dropdown values
    getCoLender() {
        return this.http.get<any>('api/v1/masters/colenders', { observe: 'response' });
    }

    // api to get coLender Details for particular application
    coLenderRetrieve(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/colender' , { observe: 'response' });
    }

    saveColender(applicationUid: string, coLenderInfo: any) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/colender/save', coLenderInfo , { observe: 'response' });
    }

    getAllBankAccountsForApplication(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/bankAccounts' , { observe: 'response' });
    }

    initiateBankAccountVerification(applicationUid: string,bankAccountUid: string) {
        return this.http.post<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/bankaccounts/' + bankAccountUid + '/verify' , { observe: 'response' });
    }

    verifyBankAccount(applicationUid: string, bankAccountUid: string, verificationDTO) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/bankAccount/' + bankAccountUid + '/verify', verificationDTO, { observe: 'response' });
    }

    saveLtvDeviationPercentage(applicationUid: string, ltvDeviationDto) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/collaterals/ltvdeviation/save' , ltvDeviationDto, { observe: 'response' });
    }

    getKycDocumentVerificationEvidence(applicationUid: string, kycUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/kyc/' + kycUid + '/verify/evidence' , { observe: 'response' });
    }

    getBankAccountVerificationEvidence(applicationUid: string, kycUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/bankAccount/' + kycUid + '/verify/evidence' , { observe: 'response' });
    }

    bookVoucher(applicationUid: string,feeUid: string) {
        return this.http.post<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/fees/' + feeUid + '/voucher/book' , { observe: 'response' });
    }

    exportToExcel(dataExportSearchCriteria: DataExportSearchCriteriaDTO) {
        dataExportSearchCriteria.accountDate = this.customDatepipe.transform(
            dataExportSearchCriteria.accountDate, 'ISODATE');
        return this.http.post('api/v1/dataport/applications/export', dataExportSearchCriteria, { observe: 'response', responseType: 'blob'})
    }

    // loan document related API's

    getLoanDocument(applicationUid: string,category:any) {
        let params = new HttpParams();
        if (category !== null) {
            params = params.set('category', category);
        }
        
        return this.http.get<any>(this.APIv1 + applicationUid + '/loanDocuments', {params, observe: 'response' });
    }

    getSigners(applicationUid: string,loanDocumentUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/loanDocuments/' + loanDocumentUid + '/signers' , { observe: 'response' });
    }

    updateLoanDocument(loanDocumentSignersDto: any,applicationUid: string,loanDocumentUid: string) {
        return this.http.put<any>(this.APIv1 + applicationUid + '/loanDocuments/' + loanDocumentUid + '/save',loanDocumentSignersDto, { observe: 'response' });
    }

    checkEsignStatus(applicationUid: string, loanDocumentUid: string) {
        return this.http.get<any>('api/v1/xdata-gateway/applications/' + applicationUid + '/loanDocuments/' + loanDocumentUid + '/esign/status' , { observe: 'response' });
    }

    downloadPartialSignedDocument(applicationUid: string, loanDocumentUid: string) {
        return this.http.get('api/v1/xdata-gateway/applications/' + applicationUid + '/loanDocuments/' + loanDocumentUid + '/esign/download' , { observe: 'response', responseType: 'blob' })
            .pipe(map((res: HttpResponse<Blob>) => {
                const out = { data: null, filename: null, type: null };
                out.data = res.body;
                const responseHeaderFilename = res.headers.get('Content-Disposition');
                out.filename = responseHeaderFilename.split(';')[1].split('=')[1].trim();
                return out;
            }));
    }

    getEstampTags(branchCode: any) {
        return this.http.get<any>('api/v1/configurations/branch/' + branchCode + '/estamptags', { observe: 'response' });
    }

    getApplicationDecisionItemGrants(applicationUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid
            + '/decisionitemgrants', { observe: 'response' });
    }

    getBureauReportVerificationEvidence(applicationUid: string, bureauReportUid: string) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/bureaureport/' + bureauReportUid + '/verify/evidence' , { observe: 'response' });
    }

    verifyBureauDetails(applicationUid: string, bureauReportUid: string, bureauResponseDTO) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/bureaureport/' + bureauReportUid + '/verify', bureauResponseDTO, { observe: 'response' });
    }

    bankAnalyzerInitiate(applicationUid:string,request){
        return this.http.post<any>('api/v1/xdg/applications/' + applicationUid + '/bankstatements/analysis/init',request, {observe: 'response' })
    }

    retriveBankDetails(applicationUid:string,request){
        return this.http.post<any>('api/v1/xdg/applications/' + applicationUid + '/bankstatements/analysis/retrive',request, {observe: 'response' })
    }

    validateCoapplicantDelete(applicationUid: string,personUid: string) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/delete/validate', { observe: 'response' });
    }

    deletePerson(applicationUid: string,personUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/person/' + personUid + '/delete', { observe: 'response' });
    }

    fetchBankStatementAnalyzerRequests(applicationUid: string) {
        let analyzerRequestDTO = {
            applicationUid: applicationUid
        }
        return this.http.post<any>('api/v1/xdata-request/applications/' + applicationUid + '/bankstatements/analysis', analyzerRequestDTO, { observe: 'response' });
    }

    pdcDelete(applicationUid: string, pdcUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/postdatecheques/' + pdcUid + '/delete', { observe: 'response' });
    }

    feeDelete(applicationUid: string, feeUid: string) {
        return this.http.delete<any>(this.APIv1 + applicationUid + '/fees/' + feeUid + '/delete', { observe: 'response' });
    }

    getEngrowAtGlance(applicationUid:string){
        return this.http.get<any>(this.APIv1 + applicationUid + '/ataglance', { observe: 'response' });
    }

    // statement analysis api's
    saveOrUpdateDossierFolder(applicationUid: string, dossierName: string) {
        let params = new HttpParams().set('dossierName', dossierName);
        return this.http.post<any>(this.APIv1 + applicationUid + '/dossiers/' + 0 + '/save', {}, { params, observe: 'response' });
    }

    getDossierFolders(applicationUid: string) {
        return this.http.get<DossierDTO[]>(this.APIv1 + applicationUid + '/dossiers', { observe: 'response' });
    }

    decryptPassword(applicationUid: any, folderInfoUid: any, fileUid:any,fileProtectInfo:any) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/folders/' + folderInfoUid + '/files/'+ fileUid + '/decrypt',fileProtectInfo , { observe: 'response' });
    }

    retriveStatementBankDetails(applicationUid:string,request){
        return this.http.get<any>('api/v1/xdg/applications/' + applicationUid + '/dossiers/' + request + '/analysis/retrieve', {observe: 'response' })
    }

    businessKycVerify(applicationUid: any, businessUid: any,idProofType: any, idProofUid:any) {
        return this.http.post<any>('api/v1/xdg/applications/' + applicationUid + '/business/' + businessUid + '/kyc/verify/'+ idProofType + '/' + idProofUid , { observe: 'response' });
    }
    
    getkfs(applicationUid: any) {
        return this.http.get<any>(this.APIv1 + applicationUid + '/kfs' , { observe: 'response' });
    }

    fetchAccountSummary(applicationUid: any,kfsRequest: any) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/kfs/init',kfsRequest , { observe: 'response' });
    }

    getKfsApr(applicationUid: any,kfsRequest: any) {
        return this.http.post<any>(this.APIv1 + applicationUid + '/kfs/apr',kfsRequest , { observe: 'response' });
    }

    getSingleDossier(applicationUid:string,dossierUid){
        return this.http.get<any>(this.APIv1 + applicationUid + '/dossiers/' + dossierUid, {observe: 'response' })
    }

    getParties(applicationUid:string,partyPlays: any){
        let params = new HttpParams().set('partyPlays', partyPlays);
        return this.http.get<any>(this.APIv1 + applicationUid + '/parties', { params, observe: 'response' })
    }

    processStatements(applicationUid:string,dossierUid: any,accontStatements: any){
        return this.http.post<any>(this.APIv1 + applicationUid + '/dossier/' + dossierUid + '/process', accontStatements, { observe: 'response' })
    }

    abortDossier(applicationUid:string,dossierUid: any): Observable<HttpResponse<any>> {
        return this.http.put<any>(this.APIv1 + applicationUid + '/dossier/' + dossierUid + '/abort', { observe: 'response' });
    }

    statementAnalyzerInitiate(applicationUid:string,statmentdossieruid){
        return this.http.post<any>('api/v1/xdg/applications/' + applicationUid + '/dossiers/' + statmentdossieruid + '/analysis/init', {observe: 'response' })
    }

}
