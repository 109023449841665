import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { User, UserSearchCriteria } from './user.model';
import { Branch } from '../branch/branch.model';
import { OrganizationStructure } from '../organization-structure/organization-structure.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private API = 'api/users';
  private roleAPI = 'api/allRoles';
  private registerAPI = 'api/register';

  constructor(private http: HttpClient) {}

  findUsers(req: any, userSearchCriteria : UserSearchCriteria): Observable<HttpResponse<any>> {
    let params = new HttpParams();
    params = params.set('page', req.page);
    params = params.set('size', req.size);
    params = params.set('sort', req.sort);
    return this.http.post<any>(this.API + '/list', userSearchCriteria, { params, observe: 'response' });
  }

  saveUser(user: User): Observable<HttpResponse<User>> {
    const copy: User = Object.assign({}, user);
    if (copy.id && copy.id != null) {
      return this.http.put<User>(this.API, copy, { observe: 'response' });
    } else {
      return this.http.post<User>(this.API, copy, { observe: 'response' });
    }
  }

  registerUser(user: User): Observable<HttpResponse<User>> {
    const copy: User = Object.assign({}, user);
    return this.http.post<User>(this.registerAPI, copy, {
      observe: 'response'
    });
  }

  getUser(username: string) {
    return this.http.get<User>(this.API + '/' + username);
  }

  deleteUser(username: string) {
    return this.http.delete(this.API + '/' + username);
  }

  getAllRoles() {
    return this.http.get<any>(this.roleAPI);
  }

  getUserByActivationKey(key: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('key', key);
    return this.http.get<User>('api/checkActivationKey', {
      params,
      observe: 'response'
    });
  }

  activateUser(key: string): Observable<any> {
    let params = new HttpParams();
    params = params.set('key', key);
    return this.http.get<User>('api/activate', { params, observe: 'response' });
  }

  //This method is to be used only for RO users list
  getUsersForBranchByRole(branchCode: string, role: string){
    return this.http.get<any>('api/v1/masters/branches/' + branchCode
      + '/users/' + role, { observe: 'response' });
  }

  getAllBranches() {
    return this.http.get<Branch[]>('api/branches/user');
  }

  getOrgStructureForRole(role: string) {
    return this.http.get<OrganizationStructure>('api/v1/masters/orgstructure/units/' + role, { observe: 'response' });
}
  
}