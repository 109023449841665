import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, MatSidenav, MatSnackBar, MatSort, DateAdapter } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Note } from '@app/note/note.model';
import { NoteService } from '@app/note/note.service';
import { ListSource } from '@ig-core/list/list-source';
import { PagingAndSortParams } from '@ig-core/list/paging-and-sort-params';
import { ApplicationsDataSource } from './applications.data.source';
import { ApplicationService } from './application.service';
import { LoanApplicationLite, ApplicationSearchCriteria, RecentApplicationsLocalDataComposite } from './applications.model';
import { takeUntil, delay } from 'rxjs/operators';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { MediaObserver , MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import {take}from 'rxjs/operators';
import { IgFileService } from '@ig-core/form/igFile.service';
import { BranchService } from '@app/admin/branch/branch.service';
import { DashboardService} from '@app/dashboard/dashboard.service';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { RecentOpenedApplicationsService } from './recent-opened-applications.service';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'eng-application',
  templateUrl: './applications.template.html',
  styleUrls: ['./applications.styles.scss'],
  animations: [
    trigger('openCloseApplicationSidenav', [
      state('openApplication', style({ width: '22vw' })),
      state('closeApplication', style({ width: '0px' })),
      transition('open <=> closed',
        animate('0.5s')
      ),
    ]),
    trigger('openCloseApplicationSidenavContent', [
      state('openApplication', style({ 'margin-right': '22vw' })),
      state('closeApplication', style({ 'margin-right': '0px' })),
      transition('open <=> closed',
        animate('0.5s')
      ),
    ])
  ]
})
export class ApplicationsComponent implements OnInit, AfterViewInit, OnDestroy {

  pagingAndSortParams: PagingAndSortParams;

  selectedApplication: LoanApplicationLite;

  manualPage:number;
  
  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  
  @ViewChild(MatSort)
  sort: MatSort;

  @ViewChild('applicationSidenav')
  public applicationSidenav: MatSidenav;
  public isApplicationSidenavOpen = false;

  businessImage: any;
  personImageDetails:any;
  imageLoaded: boolean;

  applicationDisplayColumns: string[]
  dataSource: ApplicationsDataSource;
  listSource: ListSource;
  recentOpenedApplications:Array<RecentApplicationsLocalDataComposite>=[];
  
  searchCriteria: ApplicationSearchCriteria;
  applicationSearchForm: FormGroup;

  currentScreenWidth: string = '';
  
  flexMediaWatcher: Subscription;

  notes: Note[];
  noteContent : string;
  isShowIncomeProfile: ReferenceCode[];
  display:any;

  loanTypes: NameValueDto[];
  leadSources: NameValueDto[] = [];

  userBranches: NameValueDto[];
  allProducts: NameValueDto[] = [];
  pageNumbers:any;
  
  showOptions: string[] = ['MY INBOX', 'ALL'];
  stages: string[] = [];
  applicantName: string;
  applicationNo: string;
  listOfFeatures: string[];
  recentSearchList:Array<any>=[];
  showRecentSearchCriteria:boolean=false;
  paramSearch:any={
    show:'MY INBOX',
    applicationDateFrom:"",
    stages:[],
    products:[],
    leadSource: [],
    branch:[],
    salesPersonId: "",
  };
  displaySearchFields: any;
  searchFilter: ReferenceCode[]

  constructor(private applicationService: ApplicationService,
      private referenceCodeService: ReferenceCodeService,
      private noteService: NoteService,
      private fileService: IgFileService,
      private branchService: BranchService,
      private formBuilder: FormBuilder,
      private activatedRoute: ActivatedRoute,
      private _snackbar: MatSnackBar,
      private router: Router,
      private media: MediaObserver,
      private dashboardService:DashboardService,
      private imagePreviewDialogService:ImagePreviewDialogService,
      private recentOpenedApplicationsService:RecentOpenedApplicationsService,
      private dateAdapter: DateAdapter<Date>) {
    this.flexMediaWatcher = media.media$.subscribe((change: MediaChange) => {
      if(change.mqAlias !== this.currentScreenWidth) {
        this.currentScreenWidth = change.mqAlias;
        if(this.isApplicationSidenavOpen){
          this.closeSidenav()
        }
        this.setTableColumns();
      }
    });
    this.dateAdapter.setLocale('en-IN');
  }

  setTableColumns() {
     // this is to get what are the features need to shown.
    // we will check if the particular name is present in listOfFeatures then we will show else we will dissable particular feature
    this.referenceCodeService.getFeatureSet().subscribe(response =>{
      this.listOfFeatures = response.body.map(feature => feature.toLocaleLowerCase());

      this.applicationDisplayColumns = ['uid','branchCode', 'applicantName', 'product', 'amount','submissionDate',
    'currentWorkflowStage','WaitingTime', 'ro','turnAroundTime']
    // here default value for applicationDisplayColumn we are setting uid, if featureset application number is enabled then we will replace uid with application number 
    if (this.listOfFeatures.includes('applicationno')) {
      this.applicationDisplayColumns.splice(0, 1, 'applicationNo');
    }
  })

    if (this.currentScreenWidth === 'xs') {
      this.applicationDisplayColumns = ['branchCode', 'applicantName'];
    }
  }

  ngOnInit() {
    // here we are setting openedApplicationDate to null to get all the values, here in this screan we need to display all the dropdown,
    // this is list of application, if we don't set to null it will show the dropdown values based on previously opened application
    this.recentOpenedApplicationsService.entityCreatedDate(null);
    this.getReferenceCodes();
    this.getUserBranches();
    this.getAllProductsFromProductMaster();
    this.getDistinctStages();
//the below code is refer when user redirect from dashboard, based on click this search criteria will be set.
    if(this.dashboardService.getSearchCriteria()!=null){
      let searchCriteria=this.dashboardService.getSearchCriteria()
      this.paramSearch.applicationDateFrom= searchCriteria.applicationDateFrom
      this.paramSearch.show=searchCriteria.show,
      this.paramSearch.stages=searchCriteria.stages,
      this.paramSearch.leadSource = searchCriteria.leadSource,
      this.paramSearch.product = searchCriteria.productType,
      this.paramSearch.branch = searchCriteria.branch,
      this.paramSearch.salesPersonId = searchCriteria.salesPersonId
    }

    //Instantiate empty search form
    this.applicationSearchForm = this.formBuilder.group({
      show:this.paramSearch.show ,
      stages: [this.paramSearch.stages],
      branches:[this.paramSearch.branch],
      products: [this.paramSearch.product],
      applicationDateFrom: this.paramSearch.applicationDateFrom,
      applicationDateTo: '',
      applicantName: '',
      applicationNo:'',
      leadSource: [this.paramSearch.leadSource],
      salesPersonId: this.paramSearch.salesPersonId
    });

    //Instantiate dataSource for subscribing changes in list data
    this.dataSource = new ApplicationsDataSource(this.applicationService);
   
    //Instantiate listSource for subscribing changes in search criteria
    this.listSource = new ListSource(this.activatedRoute, this.paginator, this.sort);
    
    //Subscribe to changes in search criteria
    this.listSource.getListParams().pipe(takeUntil(componentDestroyed(this)))
        .subscribe((listParams) => {
      this.pagingAndSortParams = listParams.pagingAndSortParam;
      this.getApplications();
    });
    this.recentOpenedApplications=this.recentOpenedApplicationsService.getRecentOpenedApplications()
  }

  getReferenceCodes() {
    this.referenceCodeService.getShortRefCodes('loan_type').subscribe((response: any) => {
      this.loanTypes = response.loan_type;
    });
    this.referenceCodeService.getShortRefCodes('sourcing_code').subscribe((response: any) => {
      this.leadSources = response.sourcing_code;
    });
    this.referenceCodeService.getRefCodesForClassifier('settings').subscribe((response: any) =>{
      this.searchFilter = response;
      // this is default value which need to display in top search parameters
      this.displaySearchFields = ["firstname","applicationno","branch","stage","submissiondate"]

      this.searchFilter.forEach(element => {
        if(element.code == "application-search-filters"){
          if (element.field1 !== null){
          this.displaySearchFields = element.field1.split(',');
          this.displaySearchFields = this.displaySearchFields.map(field => field.toLowerCase());
          }
        }
      })
    })
  }

  getUserBranches() {
    this.branchService.getBranchesForUser().subscribe((response: any) => {
      if (response) {
        this.userBranches = response.body;
      }
    });
  }

  getAllProductsFromProductMaster() {
    this.referenceCodeService.getAllProducts().subscribe((response: any) => {
      if(response) {
        response.body.forEach(product => {
          this.allProducts.push({
            'code': product.productCode,
            'name': product.productName
          } as NameValueDto);
        });
      }
    });
  }

  getDistinctStages() {
    this.referenceCodeService.getDistinctStages().subscribe((response: any) => {
      if(response) this.stages = response.body;
    });
  }

  getApplications(event?:any,from ?: string) {
    if(from == "onClickSearch"){
      this.pagingAndSortParams.pageIndex =0,
      // on click of search we are clearing these two fields because these values are from dashboard component and there is not input field for these two fields.
      // hence user will be confused,so we are clearing these two fields on click of search button, and search will be only based on values from the input search fields
      this.applicationSearchForm.patchValue({
        leadSource: '',
        salesPersonId: ''
      });
    }
    if(event != undefined && event != null ){
      this.paginator.pageIndex =event
      this.pagingAndSortParams.pageIndex =event
    }
    this.generateApplicationSearchCriteria();
    
    // Take sorting criteria from 'pagingAndSortParams'. By default sort by 'submissionDate, desc'  
    let sortCriteria = 'submissionDate' + ',' + 'desc';
    if(this.pagingAndSortParams.sortColumn) {
      sortCriteria = this.pagingAndSortParams.sortColumn + ','
        + this.pagingAndSortParams.sortDirection;
    }
    if(from == "onClickSearch"){
      this.recentOpenedApplicationsService.addRecentSearchCriteria(this.searchCriteria)
    }
    this.manualPage=undefined
    // Subscribe to changes in list data
    this.dataSource.getApplications(sortCriteria, this.pagingAndSortParams.pageIndex,
      this.pagingAndSortParams.pageSize, this.searchCriteria);
      
      setTimeout(()=>{      
      let count= this.dataSource.totalCount$.value
      this.pageNumbers = this.paginator.getNumberOfPages()
    },1000)
    this.pageNumbers = this.paginator.getNumberOfPages()
  }

  generateApplicationSearchCriteria() {
    this.searchCriteria = {
      applicationDateFrom: this.applicationSearchForm.value.applicationDateFrom,
      applicationDateTo: this.applicationSearchForm.value.applicationDateTo,
      // to maintain consistancy setting null and for string values we are trimming the values.
      productTypes: (this.applicationSearchForm.value.products && this.applicationSearchForm.value.products.length > 0) ? this.applicationSearchForm.value.products : null,
      stages: (this.applicationSearchForm.value.stages && this.applicationSearchForm.value.stages.length > 0) ? this.applicationSearchForm.value.stages : null,
      branches: (this.applicationSearchForm.value.branches && this.applicationSearchForm.value.branches.length > 0) ? this.applicationSearchForm.value.branches : null,
      show: this.applicationSearchForm.value.show,
      applicantName: this.applicationSearchForm.value.applicantName.trim(),
      applicationNo: this.applicationSearchForm.value.applicationNo.trim(),
      // for these two fields value comes from dashboard component, there is no input field for these two fields, 
      leadSource: (this.applicationSearchForm.value.leadSource && this.applicationSearchForm.value.leadSource.length > 0) ? this.applicationSearchForm.value.leadSource : null,
      salesPersonId: this.applicationSearchForm.value.salesPersonId.trim()
    }
  }

  ngAfterViewInit(): void {
    //Subscribe to changes in pagination and sort criteria
    this.listSource.subscribePaginationAndSortChange()
      .pipe(takeUntil(componentDestroyed(this))).subscribe(
        () => this.loadApplicationPageWithParams());
       
  }

  //On click of search button. Validate and call getApplication().
  //CURRENTLY NOT IN USE BUT THAT MAY CHANGE SO DO NOT REMOVE.  
  /* searchApplications() {
    let fromDate = this.applicationSearchForm.value.applicationDateFrom;
    let toDate = this.applicationSearchForm.value.applicationDateTo;
    
    if(!fromDate) fromDate = "";
    if(!toDate) toDate = "";
    
    if((fromDate === "" && toDate === "") || (fromDate !== "" && toDate !== "")) {
      this.getApplications();
    }
  } */

  loadApplicationPageWithParams() {
    this.router.navigate([], this.getQueryParams());
  } 

  getQueryParams() {
    const queryParams = this.listSource.getQueryParamWithPaginationAndSortParams();
    return queryParams;
  }

  ngOnDestroy(): void {
    this.listSource.disconnect();
    this.dashboardService.updateSearchCriteria(null)
  }

  openSideNav(selectedApplication) {
    setTimeout(() => {
      if(!this.selectedApplication || (this.selectedApplication.uid 
          !== selectedApplication.uid)) {
        this.selectedApplication = selectedApplication;
        this.applicationSidenav.open();
        this.isApplicationSidenavOpen = true;
        this.loadBusinessImage(this.selectedApplication.businessImageFolderUid);
        this.getNotes(this.selectedApplication.uid);
      }
    }, 250); //250 = delay between 2 single clicks. Faster clicks are perceived as a double click.
  }

  loadBusinessImage(folderUid: string) {
    if(!folderUid) {
      this.businessImage = null;
      this.imageLoaded = false;
    } else {
      //Fetch all FileDtos stored in the folder
      this.fileService.getAllFilesFromFolder(folderUid).subscribe((response) => {
        if(response && response.body && response.body.length > 0) {
          this.personImageDetails=response.body
          //If response received then use fileId from response's first object to stream
          this.fileService.fileStreamById(response.body[0].fileId,
              "true").subscribe(image => {
            let reader = new FileReader();
            reader.addEventListener("load", () => {
              //"businessImage" is used in img src attr
              this.businessImage = reader.result;
              this.imageLoaded = true;
            }, false);
            if(image) reader.readAsDataURL(image.data);
          });
        } else {
          this.businessImage = undefined;
          this.imageLoaded = false;
        }
      });
    }
  }

  closeSidenav() {
    this.applicationSidenav.close();
    this.isApplicationSidenavOpen = false;
  }

  saveNote() {
    if(this.noteContent) {
      let note : Note = {
        linkToUid : this.selectedApplication.uid,
        linkToType : 'application',
        content : this.noteContent
      }
      this.noteService.saveNote(note).toPromise().then(
        (success) => {
          this._snackbar.open("Note updated successfully", "Close" ,{
            duration:2000,
          });
          this.noteContent = '';
          this.getNotes(this.selectedApplication.uid);
        },
        (failure) => {
          let errorMessage =[]
            errorMessage.push("Note update failed.")
            errorMessage.push(failure)
            this.applicationService.displayErrorMessages(errorMessage);
          console.log(failure);
        }
      )
    }
  }

  getNotes(applicationUid) {
    this.noteService.getNote({linkToType: 'application',
      linkToUid: applicationUid}).subscribe((response) => {
        this.notes = response.body;
    });
  }

  openApplication(applicationUid) {
    this.router.navigate(['/applications', applicationUid]);
  }

  //this function will trigger on clicking of image,will open up a mat-dialog
  openImageViewElement() {
    let fileDetails=  {
      folderInfo : {},
      fileInfo :  [],
    }
    fileDetails.fileInfo=this.personImageDetails
   this.imagePreviewDialogService.open({ "images": fileDetails})
 }

 openRecentSearchCard(){
   this.showRecentSearchCriteria=!this.showRecentSearchCriteria
   this.recentSearchList=this.recentOpenedApplicationsService.getRecentSearchCriteria()
 }

 onClickSearchList(record){
  this.applicationSearchForm.patchValue({
    show:record.show ,
    stages:record.stages,
    branches:record.branches,
    products: record.productTypes,
    applicationDateFrom: record.applicationDateFrom,
    applicationDateTo: record.applicationDateTo,
    applicantName: record.applicantName,
    applicationNo: record.applicationNo
  });
  this.showRecentSearchCriteria=!this.showRecentSearchCriteria
 }


closeRecentSearch(){
  this.showRecentSearchCriteria=false
}

goToRecentApplication(goToApplication){
  this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
    this.router.navigate(['/applications',goToApplication.application.uid]);
}); 
  }
}