export class BusinessInformation {
    constructor(
        public businessEntity ?: BusinessEntity,
        public entityProfile ?: EntityProfile,
        public partyLink ?: PartyLink
    ) { }
}

export class BusinessEntity {
    constructor(
        public id?: number,
        public uid?: string,
        public name?: string,
        public legalName?: string,
        public dateOfIncorporation?: Date,
        public gstin?: string,
        public pan?: string,
        public form6061Flag?: boolean,
        public description?: string,
        public lat?: number,
        public lng?: number,
        public homeBranch?: string,
        public operationalStatus?: string,
        public dedupReference?: string,
        public dedupResolution?: string,
        public weekendDay1?: string,
        public weekendDay2?: string,
        public branchUid?: string,
        public profilePicFileId?: string,
        public version?: number,
        public source?: string
    ) { }
}

export class EntityProfile {
    constructor(
        public id?: number,
        public uid?: string,
        public entityId?: string,
        public constitution?: string,
        public constitutionOther?: string,
        public industryType?: string,
        public industryTypeOther?: string,
        public turnoverRange?: string,
        public turnoverOther?: string,
        public yearsInOperation?: number,
        public monthsInOperation?: string,
        public employeeCount?: string,
        public businessNature?: string,
        public businessNatureOther?: string,
        public profilePicFilePath?: string,
        public businessStructure?: string,
        public workhoursPerWeek?: number,
        public businessWithoutOwner?: boolean,
        public primaryPhone?: string,
        public alternatePhone?: string,
        public landlinePhone?: string,
        public emailId?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: number,
        public entityUid?: number,
        public version?: number,
        public yearsInBusiness?: number
    ) { }
}

export class PartyLink {
    constructor(
        public uid?: string,
        public partyType?: string,
        public partyId?: string,
        public partyUid?: string,
        public partyPlay?: string,
        public relationshipType?: string,
        public referenceFor?: string,
        public referenceForUid?: string,
        public primaryPartyFlag?: boolean,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public version?: number
    ) { }
}