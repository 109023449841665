
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrgStructureSearchCriteria, OrganizationStructure } from './organization-structure.model';
import { Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class OrganizationStructureService {
    private API = 'api/v1/masters/orgstructure';
    private API1 = 'api/masters/orgstructure/';
    constructor(private http: HttpClient) { }

    getOrgStructureData(req: any, searchCriteria: OrgStructureSearchCriteria): Observable<HttpResponse<OrganizationStructure[]>> {
        let params = new HttpParams();
        params = params.set('page', req.page);
        params = params.set('size', req.size);
        params = params.set('sort', req.sort);
        params = params.set('chain', searchCriteria.chain);
        if (searchCriteria.level != undefined, searchCriteria.level != '') {
            params = params.set('level', searchCriteria.level);
        }
        return this.http.get<any>(this.API, { params, observe: 'response' });
    }

    getLevels(chain) {
        let params = new HttpParams();
        if (chain && chain !== '') {
            params = params.set('chain', chain);
        }
        return this.http.get<any[]>(this.API1 + 'levels', { params, observe: 'response' });
    }

    getOrgStructure(orgStructureUid: string) {
        return this.http.get<any>(this.API + '/' + orgStructureUid, { observe: 'response' });
    }

    getParentUnit(chain: string, level: string) {
        return this.http.get<any>(this.API1 + 'chains/' + chain + '/levels/' + level + '/parentunits', { observe: 'response' });
    }

    saveOrgStructure(orgStructureUid: String, organizationStructure: OrganizationStructure): Observable<HttpResponse<OrganizationStructure>> {
        if (organizationStructure.uid && organizationStructure.uid != null) {
            return this.http.put<OrganizationStructure>(this.API + '/' + orgStructureUid +'/save', organizationStructure, { observe: 'response' });
        } else {
            return this.http.post<OrganizationStructure>(this.API + '/' + 0 + '/save', organizationStructure, { observe: 'response' });
        }
    }

    fetchUserByOrgStructure(orgstructureUid: string) {
        return this.http.get<any>(this.API + '/' + orgstructureUid + '/users', { observe: 'response' });
    }
}