import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoanApplication } from '@app/applications/applications.model';
import { ApplicationService } from '../../../application.service';
import { applicationTrail } from '../../decisions/decision.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DossierCompositeDTO, DossierDTO, PartyInfoDTO } from '../dossier/dossier.model';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';
import { MatSnackBar } from '@angular/material';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
@Component({
  selector: 'eng-analysis-summary',
  templateUrl: './analysis-summary.template.html',
  styleUrls: ['../../application-details.styles.scss', './analysis-summary.styles.scss']
})
export class AnalysisSummaryComponent implements OnInit {
  @Input() application: LoanApplication

  dossierFilesForm: FormGroup;
  bankStatementAnalysisResponse: DossierCompositeDTO;
  partyPlays: PartyInfoDTO[];
  slectedDossier : DossierDTO;
  businessAccountTypes: NameValueDto[];
  personAccountTypes: NameValueDto[];
  accountTypes: NameValueDto[];

  @Output() changeSelectedMenu: EventEmitter<string> = new EventEmitter();
  constructor(private applicationService: ApplicationService,
    private formBuilder: FormBuilder,
    private recentOpenedApplicationsService:RecentOpenedApplicationsService,
    private _snackbar: MatSnackBar,
    private referenceCodeService: ReferenceCodeService
  ) { }

  ngOnInit() {
    this.dossierFilesForm = this.formBuilder.group({
      listOfBankAccounts: this.formBuilder.array([]),
    })
    this.referenceCodeService.getShortRefCodes('person_bankac_type').subscribe((response: any) => {
      this.personAccountTypes = response.person_bankac_type;
      this.getBusinessAccountTypes();
    });
    this.slectedDossier = this.recentOpenedApplicationsService.getSelectedDossier();
    this.getStatementAnalysisResponse(this.application.uid,this.slectedDossier.uid);
  }

  getBusinessAccountTypes(){
    this.referenceCodeService.getShortRefCodes('business_bankac_type').subscribe((response: any) => {
      this.businessAccountTypes = response.business_bankac_type;
      this.accountTypes = this.personAccountTypes.concat(this.businessAccountTypes);
    });
  }

  getStatementAnalysisResponse(applicaitonUid,dossierUid) {
    this.applicationService.getSingleDossier(applicaitonUid,dossierUid).subscribe(response =>{
      this.bankStatementAnalysisResponse = response.body;
      this.buildForm()
    })
  }

  buildForm(){
    let partyPlays = 'applicant,coapplicant,business';
    this.applicationService.getParties(this.application.uid,partyPlays).subscribe((response: any) => {
        this.partyPlays = response.body;
    })
    const array = this.dossierFilesForm.controls.listOfBankAccounts as FormArray;
    while (array.length !== 0) {
      array.removeAt(0);
    }
    this.bankStatementAnalysisResponse.accountStatements.forEach((element) => {
      const group = new FormGroup({
        isPossibleDuplicate: new FormControl(element.bankAccount.possibleDuplicate),
        accountNumber: new FormControl(element.bankAccount.accountNumber),
        accountType: new FormControl(element.bankAccount.accountType),
        accountHolderName: new FormControl(element.bankAccount.accountHolderName),
        bankName: new FormControl(element.bankAccount.bankName),
        belongsTo: new FormControl(element.bankAccount.linkToUid)
      });
      array.push(group);
    })
  }

  onProcessClick(){
    // on process click we need to set both linkToType and linkToUid in the accountStatement object
    this.bankStatementAnalysisResponse.accountStatements.forEach((element, index) => {
      this.partyPlays.forEach(ele =>{
        if(ele.uid == this.dossierFilesForm.value.listOfBankAccounts[index].belongsTo){
          element.bankAccount.linkToType = ele.partyType;
        }
      element.bankAccount.linkToUid = this.dossierFilesForm.value.listOfBankAccounts[index].belongsTo;  
    })
    })
    this.applicationService.processStatements(this.application.uid,this.slectedDossier.uid,this.bankStatementAnalysisResponse.accountStatements).subscribe(response =>{
      this._snackbar.open("Bank accounts are processed successfully", "Close", {
        duration: 6000,
      });
      this.changeSelectedMenu.emit('DOSSIER');
    })
  }

  onCancelClick(){
    this.changeSelectedMenu.emit('DOSSIER');
  }
}
