import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, EventEmitter, Output } from '@angular/core';
import { LoanApplication, ApplicationAccessSpecifier, Person } from '@app/applications/applications.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { MatSnackBar } from '@angular/material';
import { SessionStorageService } from 'angular-web-storage';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { AnalyzerRequestDTO, AnalyzerResponseDTO } from '../../business/businessBankAccount/business-bank-accounts.model';
import { USER_AGENT_WEB } from '@app/constants/data.constants';
import { FolderFilesComposite } from '../../loanInformation/loan-folder-files/loan-folder-files.model';
import { DossierCompositeDTO, DossierDTO, PartyInfoDTO } from './dossier.model';
import { AnalysisSummaryComponent } from '../summary/analysis-summary.component';
import { RecentOpenedApplicationsService } from '@app/applications/recent-opened-applications.service';
@Component({
  selector: 'eng-dossier',
  templateUrl: './dossier.component.html',
  styleUrls: ['../../application-details.styles.scss', './dossier.component.css']
})
export class DossierComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() menuCode: string;
  @Input()
  applicant: Person;

  selectedFolder: any;
  selectedFolderDossier: DossierDTO = {};

  documents: Files[] = [];
  dossierDetails: DossierDTO[] = [];
  allFilesInFolder: FolderFilesComposite;
  fileData: File

  allowAccess: Boolean;
  showFolderAdd: boolean = false
  isFormDisabled: Boolean = true;
  menuItemAllowAccess: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  dossierFilesForm: FormGroup;

  // for dossier component
  selectedIndex: any;
  bankAnalyzerResponse: DossierCompositeDTO;
  applicants: any;
  accountTypes: NameValueDto[];
  partyPlays: PartyInfoDTO[];
  cfaFinalized: boolean;

  @ViewChild(AnalysisSummaryComponent) childComponent: AnalysisSummaryComponent;

  @Output() changeSelectedMenu: EventEmitter<string> = new EventEmitter();
  constructor(
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private $sessionStorage: SessionStorageService,
    private fileService: IgFileService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private confirmationDialogService:ConfirmationDialogService,
    private recentOpenedApplicationsService:RecentOpenedApplicationsService
  ) {
    this.allowAccess = this.applicationService.allowAccess;
  }
  ngOnInit() {
    this.dossierFilesForm = this.formBuilder.group({
      newFolderName: '',
      selectedFolder: '',
      password: ''
    })
    this.getDossierFolders();
    this.getCfaStatus();
    this.getMenuItemAccess();
  }

  getDossierFolders() {
    this.applicationService.getDossierFolders(this.application.uid).subscribe(response => {
      this.dossierDetails = response.body
      if (this.dossierDetails.length != 0) {
        this.isFormDisabled = true
        this.getSelectedDossier(this.dossierDetails[0]);
      }
    }, error => {
      let errormesg =[]
            errormesg.push('Feching Folders data Failed')
            errormesg.push(error)
            this.applicationService.displayErrorMessages(errormesg);
    })
  }

  getSelectedDossier(dossier ?: DossierDTO){
    this.dossierFilesForm.controls.selectedFolder.patchValue(dossier.uid)
    this.selectedFolderDossier = dossier;
    this.getSelectedFolderFiles(dossier.fileFolderUid)
    this.getStatusMessage();
  }

  getSelectedFolderFiles(uid) {
    this.applicationService.getAllFilesInApplicationFolder(this.application.uid, uid).subscribe((response: any) => {
      if (response) {
        this.allFilesInFolder = response.body;
        this.convertBytesToSize();
      }
    })
  }

  getStatusMessage(){
    if(this.selectedFolderDossier.status == null){
      this.selectedFolderDossier.message = 'Statment analysis is not yet initiated on the dossier';
    }else if(this.selectedFolderDossier.status === 'INPROCESS'){
      this.selectedFolderDossier.message = 'Statment analysis is in process. Call Retrieve to know analysis status.';
    }else if(this.selectedFolderDossier.status === 'ANALYSIS_COMPLETED'){
      this.selectedFolderDossier.message = 'Statment analysis completed. ' + this.selectedFolderDossier.processedAccountsCount + ' of ' + this.selectedFolderDossier.analysisAccountsCount + ' bank accounts are processed.';
    }else if(this.selectedFolderDossier.processedAccountsCount === this.selectedFolderDossier.analysisAccountsCount){
      this.selectedFolderDossier.message = 'Statement analysis completed. All the bank accounts are processed';
    }else if(this.selectedFolderDossier.processedAccountsCount != this.selectedFolderDossier.analysisAccountsCount && this.selectedFolderDossier.status != 'FAILURE'){
      this.selectedFolderDossier.message = 'Statment analysis completed. ' + this.selectedFolderDossier.processedAccountsCount + ' of ' + this.selectedFolderDossier.analysisAccountsCount + ' bank accounts are processed';
    }else if(this.selectedFolderDossier.status === 'FAILURE'){
      this.selectedFolderDossier.message = 'Bank statement analysis failed ' + (this.selectedFolderDossier.message ? this.selectedFolderDossier.message : '');
    }
  }

  getCfaStatus() {
    this.applicationService.getCfaStatus(this.application.uid).subscribe(response => {
      this.cfaFinalized = response.body.cfaFinalized
    })
  }


  clickAddNewFolder() {
    this.showFolderAdd = true
  }

  //onclick ok function this function will trigger to create new folder
  createNewFolder() {
    this.isFormDisabled = false
    this.showFolderAdd = false
    let dossierName = this.dossierFilesForm.controls.newFolderName.value;
    this.applicationService.saveOrUpdateDossierFolder(this.application.uid, dossierName).subscribe((response: any) => {
      if (response.body) {
        this.getDossierFolders();
      }
    }, error => {

      let errormesg =[]
      errormesg.push('New Folder creation failed')
      errormesg.push(error)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  convertBytesToSize(){
    this.allFilesInFolder.fileInfo.forEach(element => {
      if (element.fileSize) {
        element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
      }
    });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  cancelForm() {
    this.showFolderAdd = false;
    this.selectedIndex = null;
  }


  //this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if(this.dossierFilesForm.controls.selectedFolder.value != ''){
       this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.selectedFolderDossier.fileFolderUid, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo })
      this.fileUploadService.confirmed().subscribe(data=>{
        this.getSelectedFolderFiles(data.folderUid)
      })
       
      
    }else{
      this._snackbar.open("Please add a folder to upload files", "Close", {
        duration: 5000,
      });
    }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromApplicationFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  //this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedDossier(this.selectedFolderDossier);
  } else {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(response.body.message)
    this.applicationService.displayErrorMessages(errormesg);
  }}, (failure) => {
    let errormesg =[]
    errormesg.push("File deleted failed")
    errormesg.push(failure)
    this.applicationService.displayErrorMessages(errormesg);
  });
}

  openfileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      })
    
  }
 
   //this function open up the image viewer dailog box
   openImagePreviewDialog() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  viewPdf(file){
    this.fileService.fileStreamById(file.fileId,"false").subscribe(res =>{
      window.open(res.data, '_blank');
    })
  }
  
  cancelPassword(){
    this.selectedIndex = null;
  }

  enterPassword(index: number) {
    if(this.selectedIndex === index){
      this.selectedIndex = null;
      return;
    }
    this.selectedIndex = index;  
    this.dossierFilesForm.controls.password.patchValue('');
  }

  submitPassword(file) {
    if (file) {
      let fileProtectInfo = {
        'password': this.dossierFilesForm.controls.password.value 
      } 
      this.selectedIndex = null;
      this.applicationService.decryptPassword(this.application.uid, file.folderUid, file.uid,fileProtectInfo).subscribe(response =>{
        this.getSelectedFolderFiles(response.body.folderInfo.uid);
      })
    }
  }

  onRetrieveClick() {
    this.applicationService.retriveStatementBankDetails(this.application.uid, this.selectedFolderDossier.uid).subscribe(response => {
        this.selectedFolderDossier = response.body;
        // on retrieve we need to update status in first card hence we are updating matching record with the updated response 
        this.dossierDetails.forEach((ele,index) => {
          if(ele.uid === this.selectedFolderDossier.uid){
            this.dossierDetails[index] = this.selectedFolderDossier;
          }
        })
        this.getStatusMessage();
        this.getSelectedFolderFiles(this.selectedFolderDossier.fileFolderUid);
      })
  }

  onViewDetailsClick(){
    // on viewDetails we need to show analysis statement screen which is different component, hencer we are emitting and updating the menuCode
    this.selectedFolderDossier.isCfaFinalized = this.cfaFinalized;
    this.recentOpenedApplicationsService.setSelectedDossier(this.selectedFolderDossier);
    this.changeSelectedMenu.emit('ANALYSISSTMT');
  }

  onAbortClick(){
    this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Yes","message":"Are you sure to abort dossier?"})
    this.confirmationDialogService.confirmed().subscribe(data=>{
      if(data){
      this.abortDossier();
      }
    })
  }

  abortDossier(){
    this.applicationService.abortDossier(this.application.uid,this.selectedFolderDossier.uid).subscribe(response =>{
      this._snackbar.open("Statement analysis abort successfull ", "Close", {
        duration: 4000,
      });
      this.getDossierFolders();
    })
  }

  onReviveClick(){  
    this.abortDossier();
  }
}
