export class ApplicantKycProof {
    constructor(
        public uid?: string,
        public kycProofType?: string,
        public kycProofDoc?: string,
        public documentNumber?: string,
        public issuingAuthority?: string,
        public documentDate?: Date,
        public documentExpiryDate?: Date,
        public validUpto?: string,
        public documentDescription?: string,
        public proofValidity?: string,
        public proofValidityNames?: string[],
        public verifiedBy?: string,
        public verificationDate?: Date,
        public verificationStatus?: string,
        public verifiedFlag?: boolean,
        public fileFolderUid?: string,
        public linkToType?: string,
        public linkToId?: string,
        public linkToUid?: string,
        public versionHistoryFlag?: boolean,
        public frozenFlag?: boolean,
        public contextType?: string,
        public contextId?: string,
        public contextUid?: string,
        public version?: number,
        public deleteBtnDisabled?: boolean//added for client side use this paramenter will not come from server
    ) { }
}

export class ApplicantKycProofType {
    constructor(
        public kycProofType?: string,
        public kycProofTypeDisplay?: string,
        public kycProofDoc?: string,
    ) { }
}


export class KycVerificationResponseComposite {
    constructor(
        public internalData?: KycVerificationResponse,
        public serviceProviderData?: KycVerificationResponse,
        public label?: string,
        public status?: string
        ) { }
    }

export class KycVerificationResponse {
    constructor(
        public documentType? : string,
        public documentNo? : string,
        public name? : string,
        public dob? : string,
        public message? : string,
        public status? : string,
        public gender? : string,
        public issueDate? : string,
        public expiryDate? : string,
        public issuingAuthority? : string,
        public state? : string,
        public district? : string,
        public city? : string,
        public address? : string,
        public sourceOfData? : string,
        public nameMatch?: boolean,
        public additionalInfo?: string,
        public dobMatch? : boolean
    ) { }
}