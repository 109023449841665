import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BusinessAddress, Address, AddressLink } from './business-address.model';
import { ApplicationService } from '@app/applications/application.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { NameValueDto } from '@ig-core/interfaces/name-value-dto';
import { LoanApplication,ApplicationAccessSpecifier} from '@app/applications/applications.model';
import { BusinessEntity } from '../businessInformation/business-information.model';
import { MatSnackBar } from '@angular/material';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
//validation code
import { ValidationService } from '../../../application-validators/validation.service'
import { ControlValidators } from '@app/applications/application-validators/control-validators.directive';
import { SETTING_DATAFORMAT_EMAIL_FORMAT, SETTING_DATAFORMAT_MASKED_PHONE_NUMBER_FORMAT } from '@app/constants/data.constants';
import { ReferenceCode } from '@app/admin/reference-code/reference-code.model';

@Component({
    selector: 'eng-business-addresses',
    templateUrl: 'business-addresses.template.html',
    styleUrls: ['../../application-details.styles.scss']
})
export class BusinessAddressesComponent implements OnInit {

    @Input()
    application: LoanApplication;

    @Input()
    business: BusinessEntity;
    @Input()
    menuCode:string;

    @ViewChild('sameAsDropdown')
    sameAsDropdown;
    
    businessAddresses: BusinessAddress[];

    businessAddressForm: FormGroup;
    isFormDisabled : boolean;
    showForm: boolean;

    //Used to store whichever address that has been selected for editing
    selectedAddress: BusinessAddress;

    //Used to flag whether selected address is a "Same As" address or not.
    isSameAsAddress: boolean;

    addressTypes: NameValueDto[];
    propertyTypes: NameValueDto[];
    
    //Used for storing available "sameAsOptions" for a selected address 
    sameAsOptions: BusinessAddress[];

    countryList: NameValueDto[];
    stateList: NameValueDto[];
    districtList: NameValueDto[];
    cityList: NameValueDto[];

    allowAccess: boolean;
    menuItemAllowAccess:boolean;
    emailFormat: ReferenceCode[];
    mobileNumberFormat: ReferenceCode[];

    applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;
    constructor(private formBuilder: FormBuilder,
        private applicationService: ApplicationService,
        private referenceCodeService: ReferenceCodeService,
        private _snackbar: MatSnackBar,
        private confirmationDialogService:ConfirmationDialogService,
        private validationService: ValidationService) {
          this.businessAddressForm = this.formBuilder.group({
            addressType:'',
            sameAs:'',
            sameAsAddress: '',
            firstLine:'',
            secondLine: '',
            thirdLine:'',
            country: '',
            state: '',
            district: '',
            city: '',
            pinCode: '',
            landmark: '',
            mobileNumber: '',
            alternateNumber: '',
            landlineNumber: '',
            email: '',
            durationOfStay: '',
            propertyOwnership: ''
          });
      this.allowAccess = this.applicationService.allowAccess;
    }

    ngOnInit() {
      this.applyValidatorsToBusinessAddresses()
      this.isFormDisabled = true;
      this.getRefCodes();
      this.getMenuItemAccess();

    }

    getRefCodes() {
      this.referenceCodeService.getShortRefCodes('business_address_type').subscribe((response: any) => {
        this.addressTypes = response.business_address_type;
      });
      this.referenceCodeService.getShortRefCodes('premise_ownership').subscribe((response: any) => {
        this.propertyTypes = response.premise_ownership;
      });
      this.referenceCodeService.getShortRefCodes('Country').subscribe((response: any) => {
        this.countryList = response.Country;
      });
      this.referenceCodeService.getRefCodesForClassifier('State').subscribe((response: any) => {
        this.stateList = response;
      });
      this.referenceCodeService.getRefCodesForClassifier('District').subscribe((response: any) => {
        this.districtList = response;
      });
      this.referenceCodeService.getRefCodesForClassifier('City').subscribe((response: any) => {
        this.cityList = response;
      });
      this.referenceCodeService.getRefCodesForClassifier('setting_dataformat').subscribe((response: any) => {
        this.emailFormat = response.filter(ele => ele.code.toUpperCase() === SETTING_DATAFORMAT_EMAIL_FORMAT);
      });
      this.referenceCodeService.getRefCodesForClassifier('setting_dataformat').subscribe((response: any) => {
        this.mobileNumberFormat = response.filter(ele => ele.code.toUpperCase() === SETTING_DATAFORMAT_MASKED_PHONE_NUMBER_FORMAT);
      });
    }

    fetchBusinessAddresses() {
      this.showForm = false;
      this.applicationService.getBusinessAddresses(this.application.uid)
        .subscribe((response:any) => {
          if(response){
            this.businessAddresses = response.businessAddresses;
            this.businessAddresses.forEach((element,index) => {
              this.businessAddresses[index].address["deleteBtnDisabled"] = true;
            });
            this.showForm = (this.businessAddresses && this.businessAddresses.length > 0);
            this.buildAddressForm();
            this.updateSameAsOptions();
          }
      });
    }

    //Update options available in "Same As" dropdown
    updateSameAsOptions() {
      //Filter -> 1.From already added business address whichever donesnt have "sameAsFlag"
      //set to true 2. AddressLink uid != selectedAddress's addresssLink uid
      this.sameAsOptions = this.businessAddresses.filter(address =>
        !address.addressLink.sameAsFlag &&
          address.addressLink.uid !== this.selectedAddress.addressLink.uid);
    }

    //For building form
    buildAddressForm(businessAddress ?: BusinessAddress, sameAsAddress ?: Address){
      if(this.showForm) {
        if(!businessAddress) {
        businessAddress = this.businessAddresses[0];
          this.businessAddresses[0].address.deleteBtnDisabled = false;
        }
        let addressLink = businessAddress.addressLink;
        let address = sameAsAddress ? sameAsAddress : businessAddress.address;
       // this.businessAddressForm = this.formBuilder.group({
        this.businessAddressForm.patchValue({
          addressType: addressLink.linkedAs,
          sameAs: addressLink.sameAsFlag,
          sameAsAddress: addressLink.sameAsFlag ? addressLink.addressUid : null,
          firstLine: address.line1,
          secondLine: address.line2,
          thirdLine: address.line3,
          country: address.country,
          state: address.state,
          district: address.district,
          city: address.city,
          pinCode: address.postalCode,
          landmark: address.landmark,
          mobileNumber: addressLink.primaryPhone,
          alternateNumber: addressLink.alternatePhone,
          landlineNumber: addressLink.landlinePhone,
          email: addressLink.emailId,
          durationOfStay: addressLink.yearsInProperty,
          propertyOwnership: addressLink.ownershipType
        });
        this.selectedAddress = businessAddress;
        this.isSameAsAddress = this.selectedAddress.addressLink.sameAsFlag;
      }
    // this is default pattern for email.
    this.businessAddressForm.controls['email'].setValidators([ControlValidators.patternValidator('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$', "Email does not match required format")]);
    // if emailFormat regex is present in field1 and regex is valid then we will validate email with respect to field1 regex pattern
    if (this.emailFormat && this.emailFormat[0].field1.trim() !== '' && this.validationService.isValidRegexPattern(this.emailFormat[0].field1)) {
      this.businessAddressForm.controls['email'].setValidators([ControlValidators.patternValidator(this.emailFormat[0].field1.trim(), "Email does not match required format")]);
      this.businessAddressForm.controls['email'].updateValueAndValidity()
    }
    // this is default pattern for mobileNumber.
    this.businessAddressForm.controls['mobileNumber'].setValidators([ControlValidators.patternValidator('((\\+91-?)|0)?[0-9]{10}', "Mobile number should be 10 digits")]);
    // if mobileNumberFormat regex is present in field1 and regex is valid then we will validate mobileNumber with respect to field1 regex pattern
    if (this.mobileNumberFormat && this.mobileNumberFormat[0].field1.trim() !== '' && this.validationService.isValidRegexPattern(this.mobileNumberFormat[0].field1)) {
      this.businessAddressForm.controls['mobileNumber'].setValidators([ControlValidators.patternValidator(this.mobileNumberFormat[0].field1.trim(), "Mobile number does not match required format")]);
      this.businessAddressForm.controls['mobileNumber'].updateValueAndValidity()
    }
    }

    //On click of "add" button 
    addNewAddress() {
      this.showForm = true;
        let newAddress = new BusinessAddress();
        newAddress.address = new Address();
        newAddress.addressLink = new AddressLink();
        this.changeSelectedAddress(newAddress, undefined, "new");
        this.isFormDisabled = false;
    }

    //Called when type of address is changed from radio button list.
    changeSelectedAddress(businessAddress : BusinessAddress, index?: number, from?: string) {
      this.showForm = true;
      this.businessAddresses.forEach((element, i) => {
        this.businessAddresses[i].address.deleteBtnDisabled = true;
      });
      if (from != "new") {
        this.businessAddresses[index].address.deleteBtnDisabled = false;
      }
  
      this.buildAddressForm(businessAddress);
      this.updateSameAsOptions();
    }

    //Called when "same as" address is changed from dropdown.
    updateSameAsAddress() {
      if(this.businessAddressForm.value.sameAs){
        this.updateAddressFields(this.getSameAsAddress().address);
      }
    }

    //Called when "Same As" checkbox is checked/unchecked
    sameAsCheckboxClicked() {
      //If "Same As" checkbox is checked
      if(this.businessAddressForm.value.sameAs){
        this.isSameAsAddress = true;
        //update "sameAsSource" for selected address with saved option. This is reflected in "Same as" dropdown
        this.businessAddressForm.patchValue({
          sameAsAddress: this.selectedAddress.addressLink.addressUid
        });
        this.sameAsDropdown.open();
        //Only in case of newly addding(uid is null), store entered address fields in model object to repopulate later
        if(!this.selectedAddress.address.uid) {
          this.setAddressFields();
        }
      }
      //If "Same As" checkbox is unchecked
      else{
        this.isSameAsAddress = false;
        this.resetAddress();
      }
    }

    //Reset address fields based on selected address's address fields.
    resetAddress() {
      if(!this.businessAddressForm.value.sameAs){
        this.businessAddressForm.patchValue({
          sameAsAddress: null,
          firstLine: null,
        secondLine: null,
        thirdLine: null,
        country:null,
        state: null,
        district: null,
        city: null,
        pinCode: null,
        landmark: null
        });
        this.updateAddressFields(this.selectedAddress.address);
      }
    }

    //Populate address fields in form
    updateAddressFields(sameAsAddress: Address) {
      this.businessAddressForm.patchValue({
        firstLine: sameAsAddress.line1,
        secondLine: sameAsAddress.line2,
        thirdLine: sameAsAddress.line3,
        country: sameAsAddress.country,
        state: sameAsAddress.state,
        district: sameAsAddress.district,
        city: sameAsAddress.city,
        pinCode: sameAsAddress.postalCode,
        landmark: sameAsAddress.landmark
      });
    }

  
    //Updates address fields in selectedAddress with those from form fields 
    setAddressFields() {
      let businessAddressFormFields = this.businessAddressForm.value;
      this.selectedAddress.address.line1 = businessAddressFormFields.firstLine;
      this.selectedAddress.address.line2 = businessAddressFormFields.secondLine;
      this.selectedAddress.address.line3 = businessAddressFormFields.thirdLine;
      this.selectedAddress.address.country = businessAddressFormFields.country;
      this.selectedAddress.address.state = businessAddressFormFields.state;
      this.selectedAddress.address.district = businessAddressFormFields.district;
      this.selectedAddress.address.city = businessAddressFormFields.city;
      this.selectedAddress.address.postalCode = businessAddressFormFields.pinCode;
      this.selectedAddress.address.landmark = businessAddressFormFields.landmark;
    }

    //Returns CompositeAddress object(AddresssLink + Address) of whichever is chosen as in dropdown 
    getSameAsAddress() {
      return this.businessAddresses.find(businessAddress => 
        businessAddress.addressLink.addressUid ===
          this.businessAddressForm.value.sameAsAddress);
    }

    saveAddress() {
      this.validationService.markFormGroupTouched(this.businessAddressForm);
      let businessAddressFormFields = this.businessAddressForm.value;
      let addressUid = this.selectedAddress.address.uid ? this.selectedAddress.address.uid : "0";
      if(!this.isFormDisabled && this.businessAddressForm.valid){
        //If new address them populate link and context related fields
        if(!this.selectedAddress.addressLink.uid) {
          this.selectedAddress.addressLink.linkToType = 'entity';
          this.selectedAddress.addressLink.linkToUid = this.business.uid;
          this.selectedAddress.addressLink.contextType = 'application';
          this.selectedAddress.addressLink.contextUid = this.application.uid;
        }
        this.selectedAddress.addressLink.linkedAs = businessAddressFormFields.addressType;
        this.selectedAddress.addressLink.sameAsFlag = businessAddressFormFields.sameAs ? 
          businessAddressFormFields.sameAs : false;
        this.selectedAddress.addressLink.primaryPhone = businessAddressFormFields.mobileNumber;
        this.selectedAddress.addressLink.alternatePhone = businessAddressFormFields.alternateNumber;
        this.selectedAddress.addressLink.landlinePhone = businessAddressFormFields.landlineNumber;
        this.selectedAddress.addressLink.emailId = businessAddressFormFields.email;
        this.selectedAddress.addressLink.yearsInProperty = businessAddressFormFields.durationOfStay;
        this.selectedAddress.addressLink.ownershipType = businessAddressFormFields.propertyOwnership;
        //If "same as" address then update sameasSource and addressUid in addressLink.
        //Address fileds will be copied from sameAsSource in server side
        if(this.isSameAsAddress) {
          let sameAsAddress = this.getSameAsAddress();
          this.selectedAddress.addressLink.sameasSource = sameAsAddress.addressLink.linkedAs;
          this.selectedAddress.addressLink.addressUid = sameAsAddress.addressLink.addressUid;
        }else{//If not same as address, then populate address fields as well and send to server.
          this.selectedAddress.addressLink.sameasSource = null;
          this.selectedAddress.addressLink.addressUid = null;
          delete this.selectedAddress.address.uid
          this.setAddressFields();
        }
        this.applicationService.saveBusinessAddress(this.application.uid,
          this.business.uid, addressUid, this.selectedAddress).toPromise().then(
            (_success) => {
              this._snackbar.open("Business address updated successfully", "Close", {
                duration: 2000,
              });
              this.isFormDisabled = true;
              this.fetchBusinessAddresses();
            },(failure) => {
              let errormesg =[]
              errormesg.push("Business address update failed.")
              errormesg.push(failure)
              this.applicationService.displayErrorMessages(errormesg);
          }
        );
      }
    }

    enableDisableForm() {
      this.isFormDisabled = false;
    }

    cancelForm() {
      this.businessAddresses.forEach((element, i) => {
        this.businessAddresses[i].address.deleteBtnDisabled = true;
      });
      if(this.businessAddresses && this.businessAddresses.length > 0) {
        this.businessAddresses[0].address.deleteBtnDisabled = false;
        this.buildAddressForm(this.businessAddresses[0]);
      } else {
        this.showForm = false;
      }
      this.isFormDisabled = true;
    }
    //this function will apply validators to form group
  applyValidatorsToBusinessAddresses() {
    this.validationService.applyValidationRules(this.businessAddressForm,"BusinessAddresses").then((controlValidators) => {
      this.fetchBusinessAddresses();
    }).catch(() => {
    })
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess(){
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
        .subscribe((response) => {
          this.applicationAccessSpecifiers = response.body;
          this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
            .find(accessSpecifier => accessSpecifier.category === this.menuCode);
          if(this.menuItemAccessSpecifier){
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
            }
      });
    }

     //this function will be called on click of delete icon on address
   deleteAddress(selectedAddress) {
    this.applicationService.addressDelete(this.application.uid, selectedAddress.addressLink.uid).subscribe((response) => {
      if(response.body.status == "success") {
      this._snackbar.open("Business address deleted successfully", "Close", {
        duration: 2000,
      });
      this.fetchBusinessAddresses();
    } else {
      let errormesg = []
      errormesg.push("Business address deleted failed")
      errormesg.push(response.body.message);
      this.applicationService.displayErrorMessages(errormesg);
    }}, (failure) => {
      let errormesg = []
      errormesg.push("Business address deleted failed")
      errormesg.push(failure)
      this.applicationService.displayErrorMessages(errormesg);
    });
  }

  openDeleteConfirmation() {
    this.confirmationDialogService.open({ "btnCancelText": "Cancel", "btnConfirmText": "Delete", "message": "Are you sure you want to delete this Business address?" })
    this.confirmationDialogService.confirmed().subscribe(data => {
      if (data) {
        this.deleteAddress(this.selectedAddress)
      }
    })
  }

}