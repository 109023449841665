import { Component, OnInit, Input, ViewChildren, QueryList } from '@angular/core';
import { LoanApplication, ApplicationAccessSpecifier } from '@app/applications/applications.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
import { ApplicationService } from '@app/applications/application.service';
import { MatSnackBar } from '@angular/material';
import { SessionStorageService } from 'angular-web-storage';
import { ImagePreviewDialogService } from '@app/utils/image-preview-dialog/image-preview-dialog.service';
import { FileUploadService } from '@app/utils/file-upload/file-upload.service';
import { IgFileService, Files } from '@ig-core/form/igFile.service';
import { DomSanitizer } from '@angular/platform-browser';
import * as fileSaver from 'file-saver';
import { ConfirmationDialogService } from '@app/utils/confirmation-dialog/confirmation-dialog.service';
import { Folder, FolderFilesComposite } from '../loan-folder-files/loan-folder-files.model';
@Component({
  selector: 'eng-docket',
  templateUrl: './docket.template.html',
  styleUrls: ['../../application-details.styles.scss', './docket.style.scss']
})
export class DocketComponent implements OnInit {
  @Input() application: LoanApplication;
  @Input() menuCode: string;

  selectedFolder: string;

  documents: Files[] = [];
  allFoldersData: Folder[] = [];
  allFilesInFolder: FolderFilesComposite;
  fileData: File

  allowAccess: Boolean;
  showFolderAdd: boolean = false
  isFormDisabled: Boolean = true;
  menuItemAllowAccess: boolean;

  applicationAccessSpecifiers: ApplicationAccessSpecifier[];
  menuItemAccessSpecifier: ApplicationAccessSpecifier;

  folderFilesForm: FormGroup;

  myThumbnail: any;

  constructor(
    private formBuilder: FormBuilder,
    private referenceCodeService: ReferenceCodeService,
    private applicationService: ApplicationService,
    private _snackbar: MatSnackBar,
    private $sessionStorage: SessionStorageService,
    private fileService: IgFileService,
    private imagePreviewDialogService: ImagePreviewDialogService,
    private fileUploadService: FileUploadService,
    private sanitizer: DomSanitizer,
    private confirmationDialogService:ConfirmationDialogService,
  ) {
    this.allowAccess = this.applicationService.allowAccess;
  }
  ngOnInit() {
    this.folderFilesForm = this.formBuilder.group({
      newFolderName: '',
      selectedFolder: ''
    })
    this.getDocket();
    this.getMenuItemAccess();
  }

  getDocket() {
    this.applicationService.getDocketFolders(this.application.uid).subscribe(response => {
      this.allFoldersData = response.body
      if (this.allFoldersData.length != 0) {
        this.isFormDisabled = true
        this.getSelectedFolderFiles(this.allFoldersData[0].uid)
      }
    }, error => {
      this.onFailure(error, 'Feching Folders data Failed')
    })
  }

  onFailure(serverErrorMessage, clientErrorMessage){
    let errormesg =[]
            errormesg.push(clientErrorMessage)
            errormesg.push(serverErrorMessage)
            this.applicationService.displayErrorMessages(errormesg);
  }

  clickAddNewFolder() {
    this.showFolderAdd = true
  }

  //onclick ok function this function will trigger to create new folder
  createNewFolder() {
    this.isFormDisabled = false
    this.showFolderAdd = false
    let newFolderObject = {
      "uid": null,
      "name": this.folderFilesForm.controls.newFolderName.value
    }
    this.applicationService.saveOrUpdateDocketFolder(this.application.uid, newFolderObject).subscribe((response: any) => {
      if (response.body) {
        this.getDocket();
      }
    }, error => {
      this.onFailure(error, 'New Folder creation failed')
    });
  }

  getSelectedFolderFiles(uid) {
    this.folderFilesForm.controls.selectedFolder.patchValue(uid)
    this.applicationService.getDocketFiles(this.application.uid, uid).subscribe((response: any) => {
      if (response) {
        this.allFilesInFolder = response.body;
        this.allFilesInFolder.fileInfo.forEach(element => {
          if (element.fileSize) {
            element["convertedFileSize"] = this.applicationService.bytesToSize(element.fileSize)
          }
        });
      }
    });
  }

  //this function will get either this menu item  is editable or readonly 
  getMenuItemAccess() {
    this.applicationService.getApplicationAccessModifiers(this.application.uid)
      .subscribe((response) => {
        this.applicationAccessSpecifiers = response.body;
        this.menuItemAccessSpecifier = this.applicationAccessSpecifiers
          .find(accessSpecifier => accessSpecifier.category === this.menuCode);
        if (this.menuItemAccessSpecifier) {
          this.menuItemAllowAccess = this.menuItemAccessSpecifier.allowAccess;
        }
      });
  }

  cancelForm() {
    this.showFolderAdd = false;
  }


  //this function open up the image upload dailog box
  //if file upload should allow single and multiple file upload, we need to pass "uploadType":"multiple"
  openImageFileUploadDialog() {
    if(this.folderFilesForm.controls.selectedFolder.value != ''){
       this.fileUploadService.open({ "uploadType": "multiple", "folderUid": this.folderFilesForm.controls.selectedFolder.value, "applicationUid": this.application.uid, "files": this.allFilesInFolder.fileInfo, "folderType":"docket" })
      this.fileUploadService.confirmed().subscribe(data=>{
        this.getSelectedFolderFiles(data.folderUid)
      })
       
      
    }else{
      this._snackbar.open("Please add a docket to upload files", "Close", {
        duration: 5000,
      });
    }
  }

  //this function will call onclick of download icon in file's section
  downloadFile(file) {
    this.applicationService.downloadFileFromDocketFolder(this.application.uid, file.folderUid, file.fileId).subscribe(image => {
      const blob = new Blob([image.data], { type: file.type });
      fileSaver.saveAs(blob, file.fileName);
      this._snackbar.open("File has been downloaded successfully", "Close", {
        duration: 5000,
      });
    })
  }

  //this function will be called on click of delete icon on file list
deleteFile(selectedFile){
  this.applicationService.fileDelete(this.application.uid, selectedFile.uid).subscribe((response) => {
    if(response.body.status == "success") {
    this._snackbar.open("File deleted successfully", "Close", {
      duration: 2000,
    });
    this.getSelectedFolderFiles(this.folderFilesForm.controls.selectedFolder.value);
  } else {
    this.onFailure(response.body.message, "File deleted failed")
  }}, (failure) => {
    this.onFailure(failure, "File deleted failed")
  });
}

  openfileDeleteConfirmation(selectedFile){
      this.confirmationDialogService.open({ "btnCancelText":"Cancel","btnConfirmText":"Delete","message":"Are you sure you want to delete this File?"})
      this.confirmationDialogService.confirmed().subscribe(data=>{
        if(data){
        this.deleteFile(selectedFile)
        }
      })
    
  }
 
   //this function open up the image viewer dailog box
   openImagePreviewDialog() {
    this.imagePreviewDialogService.open({ "images": this.allFilesInFolder })
  }

  // this function is to generate merged report 
  generateReport(allFilesInFolder){
    if(allFilesInFolder.fileInfo.length === 0){
      this._snackbar.open("Docket is empty. Add files to generate docket.", "Close", {
        duration: 5000,
      });
      return
    }
    let docketUid = allFilesInFolder.folderInfo.uid;
    this.applicationService.generateMergeReport(this.application.uid, docketUid).subscribe(response => {
      if(response){
        this._snackbar.open("Loan document generated successfully.", "Close", {
          duration: 5000,
        });
      }
    },error => {
      this.onFailure(error, 'Error downloading report')
    });
  }

  viewPdf(file){
    this.fileService.fileStreamById(file.fileId,"false").subscribe(res =>{
      window.open(res.data, '_blank');
    })
  }
}
