import { Component, OnInit } from '@angular/core';
import { DashboardService} from '../dashboard.service';
import { Router} from '@angular/router';
import { NameValueDto } from '@app/loan-od-accounts/name-value-dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BranchService } from '@app/admin/branch/branch.service';
import { ReferenceCodeService } from '@app/admin/reference-code/reference-code.service';
@Component({
  selector: 'eng-approval-queue',
  templateUrl: './approval-queue.component.html',
  styleUrls: ['./approval-queue.component.scss', '../dashboard.component.scss']
})
export class ApprovalQueueComponent implements OnInit {
  dashboardData:any;
  hideTables:boolean=false;
  showAllStages:boolean=false;
  userBranches: NameValueDto[];
  fieldSearchForm: FormGroup;  
  leadSources: NameValueDto[] = [];
  loanTypes: NameValueDto[] = [];
  constructor(private dashboardService:DashboardService,private router:Router,
    private formBuilder: FormBuilder,
    private branchService:BranchService,
    private referenceCodeService:ReferenceCodeService) { }

  ngOnInit() {
    // Initialize the form with empty values and default branch
    this.fieldSearchForm = this.formBuilder.group({
      login: '',
      branches: '', // Set the default value here
      leadSource: '',
      product: ''
    });
    this.getUserBranches();
    this.getDashboardData();
    this.getRefCodes();
  }
//get Dashboard related data

getDashboardData(){
  let branches = this.fieldSearchForm.value.branches;
  let login = this.fieldSearchForm.value.login.trim();
  let leadSource = this.fieldSearchForm.value.leadSource;
  let productType = this.fieldSearchForm.value.product;

  // if user select all and click on show then it will send option 'all' as well,
  // hence we are removing 'all' from selected branches if 'all' is included
  if (branches.includes('all')) {
    branches = branches.filter(option => option !== 'all');
  }
  this.dashboardService.getDashboradDataStagewise(branches, login,productType,leadSource).subscribe(response=>{
  this.dashboardData =response.body
  if(this.dashboardData.status == "failure"){
    this.hideTables=true
  }
  })
}


    goToApplicationList(item){ 
      let searchCriteria ={"applicationDateFrom":'',"show":"ALL","stages":[],"productType":[],"leadSource":[],"branch":[],"salesPersonId":''}
      searchCriteria.stages.push(item),
      searchCriteria.productType = this.fieldSearchForm.controls.product.value;
      searchCriteria.leadSource = this.fieldSearchForm.controls.leadSource.value;
      searchCriteria.branch = this.fieldSearchForm.controls.branches.value;
      searchCriteria.salesPersonId = this.fieldSearchForm.controls.login.value;
      this.dashboardService.updateSearchCriteria(searchCriteria)
      this.router.navigateByUrl(`applications`)
    }

// Fetch the branches available for the user
getUserBranches() {
  this.branchService.getBranchesForUser().subscribe((response: any) => {
    if (response) {
      this.userBranches = response.body;
    }
  });
}

  handleAllOption() {
    let selectedOptions = this.fieldSearchForm.value.branches;

    if (selectedOptions.includes('all')) {
      // If "ALL" is selected, select all other options
      let allBranchCodes = this.userBranches.map(branch => branch.code);
      let selectedValues = allBranchCodes.concat('all');
      this.fieldSearchForm.get('branches').setValue(selectedValues);
    }
    else {
      // If "ALL" is not selected, set the form value with empty, this will deselect all the options
      this.fieldSearchForm.get('branches').setValue([]);
    }
  }

  selectedValues(){
    let selectedOptions = this.fieldSearchForm.value.branches;
    
    // this filter we are doing to uncheck "all" option if we uncheck any one value after clicking "all"
    let updatedSelection = selectedOptions.filter(option => option !== 'all');
    this.fieldSearchForm.get('branches').setValue(updatedSelection);

    // this we are checking beacause if we select all the dropdown values that is nothing but selectAll option
    // hence adding "all" so that option "all" will be checked
    if (updatedSelection.length === this.userBranches.length) {
      let selectedValue = selectedOptions.concat('all');
      this.fieldSearchForm.get('branches').setValue(selectedValue);
    } 
  }

  getRefCodes(){
    this.referenceCodeService.getShortRefCodes('sourcing_code').subscribe((response: any) => {
      this.leadSources = response.sourcing_code;
    });

    this.referenceCodeService.getRefCodesForClassifier('loan_type').subscribe((response: any) => {
      this.loanTypes = response
    });
  }
}
